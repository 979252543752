module.exports = [
    {"word": "سلام", "pronunciation": "salam", "meaning": "hi, hello, salute, greeting, regards", "exampleFarsi": "سلام بر شما", "examplePronunciation": "Salam bar shoma", "exampleEnglish": "Hello to you"},
    {"word": "چطور", "pronunciation": "chetor", "meaning": "how", "exampleFarsi": "چطور هستی؟", "examplePronunciation": "Chetor hasti?", "exampleEnglish": "How are you?"},
    {"word": "هزار", "pronunciation": "hazar", "meaning": "thousand", "exampleFarsi": "هزار و یک شب", "examplePronunciation": "hazaro yak shab", "exampleEnglish": "A thousand and one nights"},
    {"word": "بیان", "pronunciation": "Bayan", "meaning": "explanation, statement, definition", "exampleFarsi": "لطفا این را به من بیان کنید؟", "examplePronunciation": "lotfan in ra ba man bayan konid", "exampleEnglish": "Please explain this to me"},
    {"word": "معلم", "pronunciation": "mo-alem", "meaning": "educator, instructor, mentor, teacher", "exampleFarsi": "یک معلم تاریخ", "examplePronunciation": "yak moalem-e tarikh", "exampleEnglish": "A history teacher"},
    {"word": "صلاح", "pronunciation": "salah", "meaning": "advisable, moral soundness", "exampleFarsi": "صلاح می‌دانم که پول پس‌انداز کنید", "examplePronunciation": "salah mae-danam kae pool pas andaz konid", "exampleEnglish": "I see it advisable that you save money"},
    {"word": "زبان", "pronunciation": "zaban", "meaning": "tongue, language", "exampleFarsi": "من زبان فارسی را بلدم", "examplePronunciation": "man zabane farsi ra baladam", "exampleEnglish": "I know the farsi language"},
    {"word": "شروع", "pronunciation": "shoro", "meaning": "begin, start, commence", "exampleFarsi": "فردا شروع بهار است", "examplePronunciation": "fardo shoro-ae bahar ast", "exampleEnglish": "Tomorrow is the start of spring"},
    {"word": "صحیح", "pronunciation": "sahih", "meaning": "true, correct, proper", "exampleFarsi": "حرف شما صحیح است", "examplePronunciation": "harfe shoma sahih ast", "exampleEnglish": "Your sayings are correct"},
    {"word": "آشنا", "pronunciation": "ashna", "meaning": "familiar, acquaintance", "exampleFarsi": "من با این شهر آشنا هستم", "examplePronunciation": "man ba in shahr ashna hastam", "exampleEnglish": "I am familiar with this city"},
    {"word": "کتاب", "pronunciation": "ketob", "meaning": "book, publication, volume", "exampleFarsi": "من کتاب بادبادک باز را می‌خوانم", "examplePronunciation": "man kaetob-ae baadbaadak baz ra mae-khonam", "exampleEnglish": "I am reading the Kite Runner book"},
    {"word": "جواب", "pronunciation": "jawab", "meaning": "answer, reply, response", "exampleFarsi": "احمد تیلفون را جواب داد", "examplePronunciation": "ahmad telifun ra jawab dad", "exampleEnglish": "Ahmad answered the phone"},
    {"word": "واژه", "pronunciation": "wazha", "meaning": "term, word", "exampleFarsi": "واژه های ساده", "examplePronunciation": "wazha haye sada", "exampleEnglish": "simple words"},
    {"word": "گربه", "pronunciation": "gorba", "meaning": "cat", "exampleFarsi": "من یک گربه‌ای به نام روبی دارم", "examplePronunciation": "man yak gorbayi ba name ruby daram", "exampleEnglish": "I have a cat named Ruby"},
    {"word": "آسیا", "pronunciation": "asiya", "meaning": "asia", "exampleFarsi": "آسیا بزرگترین قاره زمین است", "examplePronunciation": "asiya bozorg-tarin qareh zamin ast", "exampleEnglish": "Asia is the biggest continent on Earth"},
    {"word": "بینی", "pronunciation": "bini", "meaning": "nose", "exampleFarsi": "بینی ام از سردی رنگ سرخ شده است", "examplePronunciation": "bini-am az sardi rangae sorkh shoda ast", "exampleEnglish": "My nose has turned red from the cold"},
    {"word": "لایق", "pronunciation": "loyeq", "meaning": "able, capable, competent", "exampleFarsi": "او یک آشپز لایق می‌باشد", "examplePronunciation": "oo yak ashpaze loyeq me-boshad", "exampleEnglish": "He is a competent chef"},
    {"word": "فردا", "pronunciation": "farda", "meaning": "tomorrow", "exampleFarsi": "فردا با داکتر قرار دارم", "examplePronunciation": "farda ba doctar qarar daram", "exampleEnglish": "I have an appointment with the doctor tomorrow"},
    {"word": "کلید", "pronunciation": "kalid", "meaning": "switch, key", "exampleFarsi": "کلید موفقیت تلاش است", "examplePronunciation": "kalide mowafaqiat talash ast", "exampleEnglish": "The key to success is hard work"},
    {"word": "تکان", "pronunciation": "takan", "meaning": "shake, movement", "exampleFarsi": "او را تکان دادم تا بیدار شود", "examplePronunciation": "oo ra takan dadam ta bidar shawad", "exampleEnglish": "I shook him to wake him up"},
    {"word": "دلکش", "pronunciation": "delkash", "meaning": "fascinating, attractive", "exampleFarsi": "این آهنگ خیلی دلکش است", "examplePronunciation": "in ahang kheli delkash ast", "exampleEnglish": "This song is very attractive"},
    {"word": "قرضه", "pronunciation": "qarza", "meaning": "loan", "exampleFarsi": "من با قرضه خانه جدید خریدم", "examplePronunciation": "man ba qarzah khaneh jadid kharidam", "exampleEnglish": "I bought a new house with a loan"},
    {"word": "وداع", "pronunciation": "weda", "meaning": "parting, valediction, farewell", "exampleFarsi": "در میدان هوایی همدیگر را وداع گفتیم", "examplePronunciation": "dar maidane hawayi hamdigar ra weda goftim", "exampleEnglish": "We said farewell to each other at the airport"},
    {"word": "حقوق", "pronunciation": "hoquq", "meaning": "rights, privileges, law", "exampleFarsi": "ما باید برای حقوق بشر بجنگیم", "examplePronunciation": "ma bayad baraye hoquqe bashar bejangim", "exampleEnglish": "We should fight for human rights"},
    {"word": "منفی", "pronunciation": "manfi", "meaning": "negative, minus, destructive", "exampleFarsi": "نتیجه تست کورونا منفی است", "examplePronunciation": "natije teste corona manfi ast", "exampleEnglish": "The corona test result is negative"},
    {"word": "جالب", "pronunciation": "jaleb", "meaning": "eye-catching, notable, interesting", "exampleFarsi": "بازی برایش خیلی جالب بود", "examplePronunciation": "bazi barayash kheli jaleb bood", "exampleEnglish": "The game was very interesting to her"},
    {"word": "زخمی", "pronunciation": "zakhmi", "meaning": "casualty, wounded", "exampleFarsi": "در زلزله دیشب کسی زخمی نشد", "examplePronunciation": "dar zelzeleh dishab kasi zakhmi nashod", "exampleEnglish": "Nobody was wounded in the earthquake last night"},
    {"word": "حجیم", "pronunciation": "hajim", "meaning": "bulky, massive, voluminous, huge", "exampleFarsi": "برای حجیم کردن عضلات باید ورزش کرد", "examplePronunciation": "baraye hajim kardane azalat bayad warzesh kard", "exampleEnglish": "One should exercise to make bulky muscles"},
    {"word": "فقیر", "pronunciation": "faqir", "meaning": "deprived, penniless, poor", "exampleFarsi": "خانواده های فقیر با تحصیل میتوانند ترقی کنند ", "examplePronunciation": "khanawada-haye faqir me-tawanand ba tahsil taraqi konand", "exampleEnglish": "Poor families can improve through education"},
    {"word": "شیوه", "pronunciation": "shewa", "meaning": "approach, means, method,", "exampleFarsi": "این مشکل را با شیوه مختلف حل کنیم", "examplePronunciation": "in moshkel ra ba shewa-i mokhtalef hal konem", "exampleEnglish": "Let's solve this problem with a different approach"},
    {"word": "عاقل", "pronunciation": "aaqel", "meaning": "wise, intelligent, rational", "exampleFarsi": "از تکنولوژی عاقلانه استفاده کنیم", "examplePronunciation": "az teknolozhi aqel-ana estefada konim", "exampleEnglish": "Let's use technology wisely"},
    {"word": "طبقه", "pronunciation": "tabaqa", "meaning": "class, level, grade", "exampleFarsi": "من در طبقه نهم زندگی می‌کنم", "examplePronunciation": "man dar tabaqe nohum zendagi mekonam", "exampleEnglish": "I live in the ninth level"},
    {"word": "شنوا", "pronunciation": "shenawa", "meaning": "that can hear, listener", "exampleFarsi": "او کم شنوا است", "examplePronunciation": "oo kam shenawa ast", "exampleEnglish": "he-she is hard of hearing"},
    {"word": "مذهب", "pronunciation": "maz-hab", "meaning": "faith, religion", "exampleFarsi": "من به مذهب همگی احترام دارم", "examplePronunciation": "man ba maz-habe hamagi ehteram daram", "exampleEnglish": "I respect everyone's religion"},
    {"word": "اجاق", "pronunciation": "ojaq", "meaning": "stove, fireplace used for cooking purposes", "exampleFarsi": "امروز روی اجاق غذا پخته کردم", "examplePronunciation": "emroz ruye ojaq gheza pokhta kardam", "exampleEnglish": "I cooked food on the stove today"},
    {"word": "لایه", "pronunciation": "loyah", "meaning": "film, layer, coat", "exampleFarsi": "پیاز لایه‌های زیادی دارد", "examplePronunciation": "piyaz laya-haye ziadi darad", "exampleEnglish": "Onion has many layers"},
    {"word": "میان", "pronunciation": "meyan", "meaning": "between, among, mid", "exampleFarsi": "در مبان کفش‌ها من کفش سیاه را انتخاب کردم", "examplePronunciation": "dar meyane kafsh-ha man kafshe siyah ra entekhab kardam", "exampleEnglish": "Among the shoes I chose the black shoes"},
    {"word": "جایز", "pronunciation": "jayez", "meaning": "permissible, allowable", "exampleFarsi": "حالا بیرون رفتن بدون ماسک جایز است", "examplePronunciation": "hala birun raftan bedune mask jayez ast", "exampleEnglish": "It is now permissible to go out without a mask"},
    {"word": "عظمت", "pronunciation": "azamat", "meaning": "greatness, grandeur, vastness", "exampleFarsi": "عظمت این ورزشکار باور نکردنی است", "examplePronunciation": "azamate in warzeshkar bawar-nakardani ast", "exampleEnglish": "This athlethe's greatness is unbelievable"},
    {"word": "عاشق", "pronunciation": "aasheq", "meaning": "lover, amorous", "exampleFarsi": "ولنتاین روز عاشقان است", "examplePronunciation": "valentine roze asheqan ast", "exampleEnglish": "Valentine is the day of lovers"},
    {"word": "سردی", "pronunciation": "sardi", "meaning": "coldness, cold temperament, refrigerant", "exampleFarsi": "سردی زمستان امسال بی‌اندزه است", "examplePronunciation": "sardie zamestane emsal be-andaza ast", "exampleEnglish": "The coldness in this year's winter has no bounds"},
    {"word": "ریشه", "pronunciation": "risha", "meaning": "base, derivation, root", "exampleFarsi": "یک نهال ریشه قوی لازم دارد", "examplePronunciation": "yak nehal rishe qawi lazem darad", "exampleEnglish": "A plant requires a strong root"},
    {"word": "عامل", "pronunciation": "aamel", "meaning": "agent, factor, enforcer", "exampleFarsi": "مدیریت زمان عامل مهم در موفقیت تحصیلی است", "examplePronunciation": "muderiate zaman aamele mohem dar mowafaqiate tahsili ast", "exampleEnglish": "Time management is an important factor in academic success"},
    {"word": "پاره", "pronunciation": "para", "meaning": "piece, part, rip", "exampleFarsi": "او بعد از خواندن نامه را پاره کرد", "examplePronunciation": "oo bad az khwandan nama ra para kard", "exampleEnglish": "He-She ripped the letter after reading it"},
    {"word": "بیمه", "pronunciation": "bima", "meaning": "insurance, assurance", "exampleFarsi": "من بیمه ماشین دارم", "examplePronunciation": "man bime mashin daram", "exampleEnglish": "I have car insurance"},
    {"word": "شانه", "pronunciation": "shana", "meaning": "shoulder, comb", "exampleFarsi": "او بار سنگینی را روی شانه خود گذاشت", "examplePronunciation": "oo bare sangini ra ruye shane khod gozasht", "exampleEnglish": "He-She placed a heavy load on their shoulder"},
    {"word": "خیلی", "pronunciation": "kheli", "meaning": "many, much, very", "exampleFarsi": "این شهر خیلی قشنگ است", "examplePronunciation": "in shahr kheli qashang ast", "exampleEnglish": "This city is very beautiful"},
    {"word": "شمار", "pronunciation": "shomar", "meaning": "digit, multitude, count", "exampleFarsi": "شمار زیادی از مردم فارسی بلدند", "examplePronunciation": "shomare ziyadi az mardom farsi baladand", "exampleEnglish": "A large count of people know Farsi"},
    {"word": "متحد", "pronunciation": "motahed", "meaning": "united, allied", "exampleFarsi": "آیالات متحده امریکا", "examplePronunciation": "ayalate motahede amrica", "exampleEnglish": "United States of America"},
    {"word": "محبت", "pronunciation": "mohabat", "meaning": "affection, endearment", "exampleFarsi": "با محبت خارها گل شوند", "examplePronunciation": "ba mohabat khar ha gol shawand", "exampleEnglish": "With affection thorns shall become flowers"},
    {"word": "تلاش", "pronunciation": "talash", "meaning": "search, struggle, drive, effort", "exampleFarsi": "او یک انسان با تلاش است", "examplePronunciation": "oo yak ensane ba talash ast", "exampleEnglish": "He-She is a human with drive"},
    {"word": "هفتم", "pronunciation": "haftom", "meaning": "seventh", "exampleFarsi": "امروز هفتم مارچ است", "examplePronunciation": "emroz haftome march ast", "exampleEnglish": "Today is the seventh of March"},
    {"word": "قبول", "pronunciation": "qabul", "meaning": "accepting, admitting", "exampleFarsi": "پیشنهاد شما را قبول دارم", "examplePronunciation": "peshnehade shoma ra qabul daram", "exampleEnglish": "I accept your offer"},
    {"word": "ترقی", "pronunciation": "taraqi", "meaning": "progress, improvement, boost", "exampleFarsi": "ترقی تلاش و زمان می‌گیرد", "examplePronunciation": "taraqi talash wa zaman megirad", "exampleEnglish": "Progress takes effort and time"},
    {"word": "دوری", "pronunciation": "doori", "meaning": "remoteness, distance, abstention", "exampleFarsi": "دوری از خانه", "examplePronunciation": "doori az khana", "exampleEnglish": "Away from home"},
    {"word": "نماد", "pronunciation": "nomad", "meaning": "emblem, image, mark, sign", "exampleFarsi": "عقاب معمولاً نماد آزادی است", "examplePronunciation": "oqab mamulan nomade azadi ast", "exampleEnglish": "Eagle is usually an emblem of freedom"},
    {"word": "باور", "pronunciation": "bowar", "meaning": "belief, sentiment", "exampleFarsi": "به خود باور داشته باش", "examplePronunciation": "ba khod bawar dashta bash", "exampleEnglish": "Believe in yourself"},
    {"word": "تسلط", "pronunciation": "tasalot", "meaning": "grasp, control, dominance", "exampleFarsi": "من به سه زبان تسلط دارم", "examplePronunciation": "man ba seh zaban tasalot daram", "exampleEnglish": "I have a grasp on three languages"},
    {"word": "مفصل", "pronunciation": "mofasal", "meaning": "detailed, lengthy, full", "exampleFarsi": "ما مفصل با هم صحبت کردیم", "examplePronunciation": "ma mofasal ba ham sohbat kardem", "exampleEnglish": "We spoke to each other in detail"},
    {"word": "مهلت", "pronunciation": "mohlat", "meaning": "leeway, extension of time, grace period", "exampleFarsi": "لطفاٌ به من مهلت دهید", "examplePronunciation": "lotfan ba man mohlat dehid", "exampleEnglish": "Please give me extension of time"},
    {"word": "سفید", "pronunciation": "safid", "meaning": "white, blank, light", "exampleFarsi": "من دامن سفید را دوست دارم", "examplePronunciation": "man damane safid ra dost daram", "exampleEnglish": "I love white dress"},
    {"word": "گذشت", "pronunciation": "gozasht", "meaning": "forbearance, forgiveness, generosity", "exampleFarsi": "گذشته را فراموش کنید", "examplePronunciation": "Gozashte ra faramoosh konid", "exampleEnglish": "Forget the past"},
    {"word": "اعضا", "pronunciation": "a'za", "meaning": "member", "exampleFarsi": "همهٔ اعضا خوشحالند", "examplePronunciation": "Hameye azaa khoshhaal-and", "exampleEnglish": "All members are happy"},
    {"word": "گلاب", "pronunciation": "golab", "meaning": "rose", "exampleFarsi": "من یک بسته گلاب دارم", "examplePronunciation": "Man yek basta golab daar-am", "exampleEnglish": "I have a bunch of rose"},
    {"word": "کمتر", "pronunciation": "kamtar", "meaning": "less, fewer", "exampleFarsi": "من کمتر از ده دقیقه وقت دارم", "examplePronunciation": "Man kamtar az dah daqiqe vaqt daar-am", "exampleEnglish": "I have less than ten minutes available"},
    {"word": "وصلت", "pronunciation": "waslat", "meaning": "alliance, union", "exampleFarsi": "این وصلت را از دست ندهیم", "examplePronunciation": "In vaslat ra az dast nadahid", "exampleEnglish": "Let's not lose this connection"},
    {"word": "دسته", "pronunciation": "dastah", "meaning": "bunch", "exampleFarsi": "یک دسته گل به مادرم هدیه دادم", "examplePronunciation": "Yak dastah gol ba madaram hadiyah dad-am", "exampleEnglish": "I gave my mother a bunch of flowers as a gift"},
    {"word": "کاشف", "pronunciation": "kashef", "meaning": "discoverer, explorer", "exampleFarsi": "این دانشمند کاشف یک بیماری‌ جدیدی است", "examplePronunciation": "In daneshmand kaashefe yak bimari-ye jadidi ast", "exampleEnglish": "This scientist is a discoverer of a new disease"},
    {"word": "بازو", "pronunciation": "bazoo", "meaning": "arm", "exampleFarsi": "او بازوهای قوی دارد", "examplePronunciation": "oo bazuhay-e qavi daar-ad", "exampleEnglish": "He has strong arms"},
    {"word": "عمیق", "pronunciation": "amiq", "meaning": "deep, profound", "exampleFarsi": "دریا عمیق است", "examplePronunciation": "daryaa amigh ast", "exampleEnglish": "The river is deep"},
    {"word": "شعله", "pronunciation": "shola", "meaning": "burner, flame, flare", "exampleFarsi": "شعلهٔ آتش بلند بود", "examplePronunciation": "sho'le-ye atash boland bood", "exampleEnglish": "The fire flame was high"},
    {"word": "موقت", "pronunciation": "mowaqat", "meaning": "temporary, provisory", "exampleFarsi": "این شغل برای من فقط موقت است", "examplePronunciation": "In shoghel baraye man faqat mowaqat ast", "exampleEnglish": "This job is only temporary for me"},
    {"word": "عرصه", "pronunciation": "aresa", "meaning": "field, area, arena", "exampleFarsi": "وی با موفقیت در عرصهٔ تجارت فعالیت می‌کند", "examplePronunciation": "Wai ba mowafaqiyat dar aresaye tejarat fa'aliyat mikon-ad", "exampleEnglish": "He is successful in the field of business"},
    {"word": "پوست", "pronunciation": "post", "meaning": "skin, peel", "exampleFarsi": "آفتاب به پوست من آسیب رسانده است", "examplePronunciation": "Aftab ba post-e man asib rasandah ast", "exampleEnglish": "The sun has damaged my skin"},
    {"word": "تخمه", "pronunciation": "tokhmah", "meaning": "seed", "exampleFarsi": "من به تخمهٔ نمکی علاقه دارم", "examplePronunciation": "Man be takhmah-ye namaki alaqah dar-am", "exampleEnglish": "I like salted seeds"},
    {"word": "چرخه", "pronunciation": "charkha", "meaning": "cycle, wheel", "exampleFarsi": "همه چیز در زندگی یک چرخه است", "examplePronunciation": "Hamah chiz dar zendagi yak charkah ast", "exampleEnglish": "Everything in life is a cycle"},
    {"word": "مکرر", "pronunciation": "mokarar", "meaning": "continual, frequent, recurrent", "exampleFarsi": "شما نمی‌توانید به طور مکرر این کار را انجام دهید", "examplePronunciation": "Shoma nami-tawanid ba tawre mokarar in kâr râ anjâm dah-id", "exampleEnglish": "You cannot repeatedly do this job"},
    {"word": "آمار", "pronunciation": "aamar", "meaning": "numbers, statistics, tally", "exampleFarsi": "آمار نشان می‌دهد تعداد جمعیت افزایش یافته است", "examplePronunciation": "Âmâr neshân medahad t'edad-e jam'e-at afzâyesh yâftah ast", "exampleEnglish": "Statistics show that the population has increased"},
    {"word": "سیار", "pronunciation": "sayar", "meaning": "mobile, celestial body, stroller", "exampleFarsi": "سیارهٔ زحل حلقه‌های زیبایی دارد", "examplePronunciation": "Sayyâre-ye zohal halqahâ-ye zebâyi dârad", "exampleEnglish": "The planet Saturn has beautiful rings"},
    {"word": "رفتن", "pronunciation": "raftan", "meaning": "go, leave, departure", "exampleFarsi": "او به دفتر خود رفت", "examplePronunciation": "Oo be daftar-e khod raft", "exampleEnglish": "He went to his office"},
    {"word": "حسرت", "pronunciation": "hasrat", "meaning": "regret, longing", "exampleFarsi": "او حسرت از دست دادن فرصت را در دل خود نگه می‌دارد", "examplePronunciation": "Oo hasrate az dast dâdan-e fersat râ dar del-e khod negah meda-rad", "exampleEnglish": "He regrets losing the opportunity in his heart"},
    {"word": "زیان", "pronunciation": "zian", "meaning": "loss, detriment", "exampleFarsi": "بیشترین زیان در زمینهٔ مالی بود", "examplePronunciation": "Bishtarin zian dar zamina-ye mâli bood", "exampleEnglish": "The greatest loss was in the financial field"},
    {"word": "رویا", "pronunciation": "roya", "meaning": "daydream, fantasy", "exampleFarsi": "من همیشه رویاهای بزرگی دارم", "examplePronunciation": "Man hameshah royâhâ-ye bozorgi dâram", "exampleEnglish": "I always have big dreams"},
    {"word": "محکم", "pronunciation": "mahkam", "meaning": "firm, strong, stable", "exampleFarsi": "دیوارهای سنگی محکم اند", "examplePronunciation": "Divârhâ-ye sangi mahkam and", "exampleEnglish": "Stone walls are strong"},
    {"word": "نقشه", "pronunciation": "naqshah", "meaning": "map, plan, drawing", "exampleFarsi": "قبل از سفر باید نقشهٔ مسیر را بررسی کنید", "examplePronunciation": "Qabl az safar bâyad naqsha-ye masir râ barrasi konid", "exampleEnglish": "You should check the route map before traveling"},
    {"word": "مذکر", "pronunciation": "mozakar", "meaning": "masculine, male", "exampleFarsi": "او یک نویسندهٔ مذکر است", "examplePronunciation": "Oo yak nawesandah-ye mozakar ast", "exampleEnglish": "He is a male writer"},
    {"word": "ضایع", "pronunciation": "zaye", "meaning": "waste, spoiled, lost", "exampleFarsi": "این کار وقت و پول بسیاری ضایع کرد", "examplePronunciation": "In kâr vaqt-o pul-e besyâri zâye kard", "exampleEnglish": "This work wasted a lot of time and money"},
    {"word": "امید", "pronunciation": "omid", "meaning": "hope, dream, expectation", "exampleFarsi": "او به امید رسیدن به رویاهایش تلاش می‌کند", "examplePronunciation": "Oo ba omide rasidan ba royâhâ-yash tâlash mekonad", "exampleEnglish": "He is trying to achieve his dreams with hope"},
    {"word": "گروه", "pronunciation": "goroh", "meaning": "group, category", "exampleFarsi": "این گروه از دانشجویان باهوش هستند", "examplePronunciation": "In goroh az dâneshjuyâne bâhush hastand", "exampleEnglish": "This group consists of intelligent students"},
    {"word": "هدیه", "pronunciation": "hadia", "meaning": "favor, gift, grant", "exampleFarsi": "او به من هدیه‌ای داد که هرگز فراموشش نخواهم کرد", "examplePronunciation": "O ba man hadiya-i dâd ke hargiz farâmoshesh nakonam", "exampleEnglish": "She gave me a gift that I will never forget"},
    {"word": "ثابت", "pronunciation": "sabet", "meaning": "fixed, steady, constant", "exampleFarsi": "این عدد ثابت است و نمی‌تواند تغییر کند", "examplePronunciation": "In adad sâbet ast wa nametawanad taghyir konad", "exampleEnglish": "This number is fixed and cannot be changed"  },
    {"word": "کشمش", "pronunciation": "keshmesh", "meaning": "sultana", "exampleFarsi": "من از کشمش خشک خیلی خوشم می‌آید", "examplePronunciation": "Man az keshmesh-e khoshk khayli khosh-am me-ayad", "exampleEnglish": "I really like dried sultanas"  },
    {"word": "کودک", "pronunciation": "kodak", "meaning": "baby, child", "exampleFarsi": "امروز صبح کودکان بازی‌های جدید یاد گرفتند", "examplePronunciation": "Emrooz sobh kudakân bâzi-hâ-ye jadid yâd gereftand", "exampleEnglish": "This morning, the children learned new games"  },
    {"word": "قضیه", "pronunciation": "qaziah", "meaning": "case, matter, circumstance", "exampleFarsi": "حل قضیه برای من اصلاً ساده نبود", "examplePronunciation": "Hal-e qaziah baraye man aslan sâdah nabood", "exampleEnglish": "Solving the case was not easy for me at all"  },
    {"word": "جمله", "pronunciation": "jomlah", "meaning": "sentence, expression", "exampleFarsi": "این جمله راهنمایی خوبی برای ما بود", "examplePronunciation": "In jomlah râhnamâyi khobi baraye mâ bood", "exampleEnglish": "This sentence was a good guide for us"  },
    {"word": "آثار", "pronunciation": "as'ar", "meaning": "effects, consequences, works", "exampleFarsi": "آثار موسیقی فرهاد دریا بسیار شناخته شده هستند", "examplePronunciation": "as'ar-e musiqi-ye farhad darya besyar shenakhteh shodeh hastand", "exampleEnglish": "Farhad Darya's musical works are very well-known"},
    {"word": "برگه", "pronunciation": "bargah", "meaning": "page, sheet", "exampleFarsi": "یک برگه خالی به من بدهید، لطفاً", "examplePronunciation": "yak bargha-ye khali be man bedehid, lotfan", "exampleEnglish": "Please give me a blank sheet of paper"},
    {"word": "آدرس", "pronunciation": "a'dras", "meaning": "address", "exampleFarsi": "آدرس منزلتان را به من بدهید، لطفاً", "examplePronunciation": "a'dras-e manzel-etan ra ba man bedehid, lotfan", "exampleEnglish": "Please give me your address"},
    {"word": "بالش", "pronunciation": "balesh", "meaning": "pillow, cushion", "exampleFarsi": "لطفاً بالشتان را برای من بیاورید", "examplePronunciation": "lotfan balesh-tan ra baraye man biawarid", "exampleEnglish": "Please bring me your pillow"},
    {"word": "برنج", "pronunciation": "berenj", "meaning": "rice", "exampleFarsi": "بهترین غذا برای شام برای من برنج است", "examplePronunciation": "behtarin ghaza baraye sham baraye man berenj ast", "exampleEnglish": "The best food for dinner for me is rice"},
    {"word": "برقی", "pronunciation": "barqi", "meaning": "electric, electrical", "exampleFarsi": "شما چه نوع لوازم خانگی برقی دارید؟", "examplePronunciation": "shoma che naw'a lawaazem-e khanagi-e barqi daarid?", "exampleEnglish": "What kind of electrical appliances do you have?"},
    {"word": "امور", "pronunciation": "omur", "meaning": "affairs, matters", "exampleFarsi": "امور روزمره زندگی من بسیار مشغول‌کننده هستند", "examplePronunciation": "omur-e rozmar-e zendagi-e man besyar mashghul-konandah hastand", "exampleEnglish": "The daily affairs of my life are very busy"},
    {"word": "آرزو", "pronunciation": "arzoo", "meaning": "wish, desire, aspiration", "exampleFarsi": "", "examplePronunciation": "", "exampleEnglish": ""},
    {"word": "آرزو", "pronunciation": "arzoo", "meaning": "wish, desire, aspiration", "exampleFarsi": "من آرزوی یک زندگی شاد و سلامت دارم", "examplePronunciation": "man arzoo-ye yek zendegi shad-o salamat dar-am", "exampleEnglish": "I have a wish for a happy and healthy life"},
    {"word": "بافت", "pronunciation": "baft", "meaning": "texture, weave", "exampleFarsi": "این پارچه بافتش خیلی زیباست", "examplePronunciation": "in pârcha bâft-esh kheli zibâ-st", "exampleEnglish": "The texture of this fabric is very beautiful"},
    {"word": "امان", "pronunciation": "aman", "meaning": "safety, security, peace", "exampleFarsi": "مردم در جستجوی امنیت و آرامش هستند", "examplePronunciation": "mardom dar jostojuy-e amniat-o ârâmesh hastand", "exampleEnglish": "People are in search of safety and peace"},
    {"word": "باقی", "pronunciation": "baqi", "meaning": "remaining, left, leftover", "exampleFarsi": "من فقط ده دقیقه دیگر وقت دارم", "examplePronunciation": "man faqat dah daqiqah digar vaqt dâram", "exampleEnglish": "I only have ten more minutes left"},
    {"word": "اهلی", "pronunciation": "ahli", "meaning": "domestic, familial", "exampleFarsi": "آشپزی یکی از کارهای اهلی است", "examplePronunciation": "âshpazi yeki az kâr-hâ-ye ahli ast", "exampleEnglish": "Cooking is one of the domestic tasks"},
    {"word": "اجرا", "pronunciation": "ejra", "meaning": "execution, performance", "exampleFarsi": "اجرای این طرح بسیار سخت است", "examplePronunciation": "ejraye in tarh besyar akht ast", "exampleEnglish": "The implementation of this plan is very difficult"},
    {"word": "آتشک", "pronunciation": "atashak", "meaning": "ember, small fire", "exampleFarsi": "در اطراف آتشک‌های زیادی بود", "examplePronunciation": "dar etraf atashak-haye ziadi bood", "exampleEnglish": "There were many embers around"},
    {"word": "آگاه", "pronunciation": "agah", "meaning": "aware, conscious, knowledgeable", "exampleFarsi": "من از خبر توضیحات کامل و آگاهانه‌ای دریافت کردم", "examplePronunciation": "man az khabar tawzehate kamel wa agahana-i daryaft kardam", "exampleEnglish": "I received a complete and informed explanation of the news"},
    {"word": "برتر", "pronunciation": "bartar", "meaning": "superior, better, higher", "exampleFarsi": "محصولات این شرکت به دلیل کیفیت بالا و برتریشان مورد توجه مصرف‌کنندگان هستند", "examplePronunciation": "mahsulat-e in sherkat ba dalil-e kayfiyat-e bala va bartar-eshan mawrede tawajoh-e masraf-konandagan hastand", "exampleEnglish": "The products of this company are popular among consumers due to their high quality and superiority"},
    {"word": "ایده", "pronunciation": "aedah", "meaning": "aedah", "exampleFarsi": "من یک ایده جدید برای پروژه دارم", "examplePronunciation": "man yak aedahe jadid baraye porozhah dar-am", "exampleEnglish": "I have a new idea for the project"},
    {"word": "آمده", "pronunciation": "aamadah", "meaning": "arrived, come", "exampleFarsi": "من به پارک آمده‌ام", "examplePronunciation": "man ba park amada-am", "exampleEnglish": "I have come to the park"},
    {"word": "اذیت", "pronunciation": "aziat", "meaning": "hurt, harm, bother", "exampleFarsi": "تو اذیت شده‌ای؟", "examplePronunciation": "to aziat shoda-i?", "exampleEnglish": "Have you been hurt?"},
    {"word": "آخرش", "pronunciation": "akharash", "meaning": "at the end, finally", "exampleFarsi": "آخرش کجا رفتی؟", "examplePronunciation": "akharash koja rafti?", "exampleEnglish": "Where did you finally go?"},
    {"word": "آزاد", "pronunciation": "azad", "meaning": "free", "exampleFarsi": "تمام شهروندان حق زندگی آزاد دارند", "examplePronunciation": "tamame shahrwandan haq-e zendagi-e azad dar-and", "exampleEnglish": "All citizens have the right to live freely"},
    {"word": "ببخش", "pronunciation": "bebakhsh", "meaning": "forgive, pardon", "exampleFarsi": "ببخشید، من این کار را انجام نمی‌دهم", "examplePronunciation": "bebakhshid, man in kar ra anjam namidaham", "exampleEnglish": "Excuse me, I won't do this"},
    {"word": "اخیر", "pronunciation": "akhir", "meaning": "recent, late", "exampleFarsi": "دوستم خیلی اخیراً بازاریابی را شروع کرده است", "examplePronunciation": "dostam kheli akhiran bazaryabi ra shoro karda ast", "exampleEnglish": "My friend has recently started marketing"},
    {"word": "بشنو", "pronunciation": "beshenaw", "meaning": "listen, hear", "exampleFarsi": "لطفاً به صدای این موزیک بشموید", "examplePronunciation": "lotfan ba sadaye in muzic beshawid", "exampleEnglish": "Please listen to the sound of this music"},
    {"word": "الگو", "pronunciation": "olgoo", "meaning": "model, pattern", "exampleFarsi": "این شرکت برای ساخت این خودرو از الگوی خودروی جدید استفاده کرده است", "examplePronunciation": "in sherkat baraye sakht in khodro az olgoo-e khodrooye jadid estefadah kardah ast", "exampleEnglish": "This company has used the pattern of the new car to make this car"},
    {"word": "ارزش", "pronunciation": "arzesh", "meaning": "value, worth", "exampleFarsi": "این آثار تاریخی برای ما ارزش بسیاری دارند", "examplePronunciation": "in asar tariikhi baraye ma arzesh bisyari darand", "exampleEnglish": "These historical works have a lot of value for us"},
    {"word": "آشتی", "pronunciation": "aashti", "meaning": "reconciliation, peace, settlement", "exampleFarsi": "او وقتی با دوستش برخورد بد داشت، پس از مدتی آشتی کردند", "examplePronunciation": "oo vaqti baa dostash barkhorde bad daasht, pas az modati aashti kardand", "exampleEnglish": "He had a bad encounter with his friend, but after a while they reconciled"},
    {"word": "برخی", "pronunciation": "barkhi", "meaning": "some, several, a few", "exampleFarsi": "برخی از دانشجویان کلاس را حضوری دنبال نمی‌کنند", "examplePronunciation": "barkhi az daneshjoian class raa hozoori donbal namikonand", "exampleEnglish": "Some of the students don't attend the class in person"},
    {"word": "ایفا", "pronunciation": "eyfa", "meaning": "fulfillment, performance, execution", "exampleFarsi": "ایفای عهد، وظیفه مهم هر کسی است", "examplePronunciation": "eyfaaye ahd, vazifaye mohem har kasi ast", "exampleEnglish": "Fulfilling promises is an important duty for everyone"},
    {"word": "بخار", "pronunciation": "bokhar", "meaning": "steam, vapor", "exampleFarsi": "شما می‌توانید از بخار آب داغ برای پاک کردن صورت خود استفاده کنید", "examplePronunciation": "shoma metawanid az bokhare aab-e daagh baraae pak kardan-e surat-e khod estefadah konid", "exampleEnglish": "You can use hot water vapor to clean your face"},
    {"word": "بانک", "pronunciation": "baank", "meaning": "bank", "exampleFarsi": "من پولم را در بانک نگه داری می‌کنم", "examplePronunciation": "man poolam ra dar baank negahdaari mekonam", "exampleEnglish": "I keep my money in the bank"},
    {"word": "باخت", "pronunciation": "baakht", "meaning": "defeat, loss", "exampleFarsi": "تیم ما در مسابقه‌ای شدیدا باخت خورد.", "examplePronunciation": "tim-e ma dar mosabeqa-i shadidan bakht khord.", "exampleEnglish": "Our team suffered a heavy defeat in a competition."},
    {"word": "باطل", "pronunciation": "batel", "meaning": "void, null, invalid", "exampleFarsi": "متن این ترجمه باطل است.", "examplePronunciation": "matn-e in tarjomah batel ast.", "exampleEnglish": "The text of this translation is invalid."},
    {"word": "آهنگ", "pronunciation": "ahang", "meaning": "music, melody, tune", "exampleFarsi": "امروز صبح با یک آهنگ زیبا از خواب پریدم.", "examplePronunciation": "emrooz sobh ba yak ahang-e zeba az khāb paridam.", "exampleEnglish": "This morning I woke up with a beautiful melody."},
    {"word": "اصلی", "pronunciation": "asli", "meaning": "original, main, fundamental", "exampleFarsi": "این پرونده، نسخه اصلی مدارک را برایتان نشان میدهد.", "examplePronunciation": "in parwanda, nuskh-e asli-ye madārek ra barayetan neshan medehad.", "exampleEnglish": "This folder shows you the original documents."},
    {"word": "اتاق", "pronunciation": "otaq", "meaning": "room", "exampleFarsi": "من در اتاق خود تنها بودم", "examplePronunciation": "man dar otaqe khod tanha boodam", "exampleEnglish": "I was alone in my room."},
    {"word": "آنجا", "pronunciation": "anjā", "meaning": "there", "exampleFarsi": "آنجا چیزی دیدی؟", "examplePronunciation": "anjā chizi didi?", "exampleEnglish": "Did you see anything there?"},
    {"word": "بشکه", "pronunciation": "boshkah", "meaning": "barrel", "exampleFarsi": "آب را در بشکه ریختیم.", "examplePronunciation": "āb ra dar boshkah rikhtim.", "exampleEnglish": "We poured the water into the barrel."},
    {"word": "بزرگ", "pronunciation": "bozorg", "meaning": "big, large", "exampleFarsi": "او خانه بزرگی دارد.", "examplePronunciation": "u khāne bozorgi dārad.", "exampleEnglish": "He has a big house."},
    {"word": "آبرو", "pronunciation": "ābroo", "meaning": "honor, reputation, dignity", "exampleFarsi": "وی به آبروی خانواده خود خدمت کرد.", "examplePronunciation": "way be ābrooye khānawāde khod khedmat kard.", "exampleEnglish": "He served his family with dignity."},
    {"word": "پاکت", "pronunciation": "pakat", "meaning": "envelope, pocket", "exampleFarsi": "من نامه را در پاکت قرار دادم", "examplePronunciation": "man namah ra dar pakat qarar dadam", "exampleEnglish": "I put the letter in the envelope"},
    {"word": "فرصت", "pronunciation": "forsat", "meaning": "opportunity, chance, time", "exampleFarsi": "اگر فرصت داشتی بیا", "examplePronunciation": "agar forsat dashti bia", "exampleEnglish": "If you have the opportunity, come"},
    {"word": "مدیر", "pronunciation": "modir", "meaning": "manager, director", "exampleFarsi": "مدیر شرکت ما خیلی سخت کار می کند", "examplePronunciation": "modire sherkate ma kheli sakht kar mekonad", "exampleEnglish": "Our company manager works very hard"},
    {"word": "مزاح", "pronunciation": "mazah", "meaning": "humor, jest, joke", "exampleFarsi": "مزاح خوبی داری، همیشه ما را خندان می کنی", "examplePronunciation": "mazah khobi dari, hameshah ma ra khandan mekoni", "exampleEnglish": "You have a good sense of humor, you always make us laugh"},
    {"word": "همگی", "pronunciation": "hamagi", "meaning": "all, everyone, altogether", "exampleFarsi": "همگی به مهمانی من بیایید", "examplePronunciation": "hamagi ba mehmani man biayid", "exampleEnglish": "Everyone come to my party"},
    {"word": "نامه", "pronunciation": "namah", "meaning": "letter, epistle", "exampleFarsi": "من یک نامه به دوستم نوشتم.", "examplePronunciation": "man yak namah ba dostam naweshtam.", "exampleEnglish": "I wrote a letter to my friend."},
    {"word": "خدمت", "pronunciation": "khedmat", "meaning": "service, duty", "exampleFarsi": "من به دانشگاه رفتم برای خدمت به کشورم.", "examplePronunciation": "man be daneshgah raftam baraye khedmat be keshwaram.", "exampleEnglish": "I went to the university to serve my country."},
    {"word": "قرار", "pronunciation": "qarar", "meaning": "agreement, appointment", "exampleFarsi": "ما یک قرار با دوستانمان برای دیدار داریم.", "examplePronunciation": "ma yak qarar ba dostaneman baraye didar darim.", "exampleEnglish": "We have an appointment with our friends to meet."},
    {"word": "درصد", "pronunciation": "darsad", "meaning": "percent", "exampleFarsi": "شما چند درصد اطلاعات در مورد این موضوع دارید؟", "examplePronunciation": "shoma chand darsad etelaat dar mawrede in mawzo darid?", "exampleEnglish": "How much percent of information do you have about this topic?"},
    {"word": "کیلو", "pronunciation": "kilo", "meaning": "kilogram", "exampleFarsi": "من ده کیلو سیب خریدم.", "examplePronunciation": "man dah kilo seb kharidam.", "exampleEnglish": "I bought ten kilograms of apples."},
    {"word": "هفته", "pronunciation": "haftah", "meaning": "week", "exampleFarsi": "ما هر هفته با همدیگر دیدار می‌کنیم", "examplePronunciation": "ma har haftah baa hamdigaar didaar mikonim", "exampleEnglish": "We meet each other every week."},
    {"word": "مبلغ", "pronunciation": "mablagh", "meaning": "amount, sum, price", "exampleFarsi": "مبلغ لازم را به ما بدهید", "examplePronunciation": "mablagh-e lazem raa be ma bedehid", "exampleEnglish": "Please give us the amount needed."},
    {"word": "نکات", "pronunciation": "nokat", "meaning": "points, details, notes", "exampleFarsi": "لطفاً نکاتی که امروز دارید، برای ما بفرستید", "examplePronunciation": "lotfan nokati ke emruz daariid, baraa-ye maa befrestid", "exampleEnglish": "Please send us the points you have for today."},
    {"word": "علاج", "pronunciation": "elaaj", "meaning": "treatment, remedy, cure", "exampleFarsi": "ما به دنبال یافتن یک روش علاج جدید هستیم", "examplePronunciation": "maa ba donbaal-e yaaftan-e yak rawesh-e elaaj-e jadid hastim", "exampleEnglish": "We are looking for a new treatment method."},
    {"word": "عبور", "pronunciation": "oboor", "meaning": "passing, crossing", "exampleFarsi": "عبور از خیابان", "examplePronunciation": "oboor az khiaban", "exampleEnglish": "Crossing the street"},
    {"word": "لطفا", "pronunciation": "lotfan", "meaning": "please", "exampleFarsi": "لطفا آب بدهید", "examplePronunciation": "lotfan ab bedehid", "exampleEnglish": "Please give me some water"},
    {"word": "طوطی", "pronunciation": "tuti", "meaning": "parrot", "exampleFarsi": "طوطی ها صدا می کنند", "examplePronunciation": "tutiha sada mekonand", "exampleEnglish": "Parrots make noise"},
    {"word": "درجه", "pronunciation": "daraja", "meaning": "degree, rank, level", "exampleFarsi": "این شغل یک درجه بالاتر از شغل پیشین من است", "examplePronunciation": "in shogh yak daraja balatar az shoghle pishineman ast", "exampleEnglish": "This job is one level higher than my previous job"},
    {"word": "مسیر", "pronunciation": "masir", "meaning": "route, path", "exampleFarsi": "مسیر سفر به شهر را نشانم بده", "examplePronunciation": "masire safar ba shahr ra neshanam bede", "exampleEnglish": "Show me the route to the city"},
    {"word": "دیگر", "pronunciation": "digar", "meaning": "other, different", "exampleFarsi": "راه دیگری باید پیدا کنیم", "examplePronunciation": "rah-e digari bayad peyda konim", "exampleEnglish": "We need to find another way"},
    {"word": "جعبه", "pronunciation": "jabah", "meaning": "box", "exampleFarsi": "لطفاً این جعبه را برای من بگذارید", "examplePronunciation": "lotfan in jabah ra baray-e man begzarid", "exampleEnglish": "Please leave this box for me"},
    {"word": "مقیم", "pronunciation": "moqim", "meaning": "resident, inhabitant", "exampleFarsi": "من در این شهر مقیم هستم", "examplePronunciation": "man dar in shahr moqim hastam", "exampleEnglish": "I am a resident in this city"},
    {"word": "جدید", "pronunciation": "jadid", "meaning": "new, fresh, novel", "exampleFarsi": "این یک محصول جدید است.", "examplePronunciation": "in yek mahsul-e jadid ast.", "exampleEnglish": "This is a new product."},
    {"word": "کتان", "pronunciation": "katān", "meaning": "linen", "exampleFarsi": "این پیراهن از کتان ساخته شده است.", "examplePronunciation": "in pirāhan az katān sākhtah shodah ast.", "exampleEnglish": "This shirt is made of linen."},
    {"word": "تشنه", "pronunciation": "tashnah", "meaning": "thirsty", "exampleFarsi": "در این روز گرم، همیشه احساس تشنگی می کنم.", "examplePronunciation": "dar in ruz-e garm, hameshah ehsās-e tashnagi mikonam.", "exampleEnglish": "On hot days like this, I always feel thirsty."},
    {"word": "نیکی", "pronunciation": "niki", "meaning": "kindness, goodness", "exampleFarsi": "نیکی که به دیگران می کنیم، همواره به ما بر می گردد.", "examplePronunciation": "niki keh ba digaran mekonim, hamwarah be ma bar migardad.", "exampleEnglish": "The kindness we do to others always comes back to us."},
    {"word": "منزل", "pronunciation": "manzel", "meaning": "house, dwelling place", "exampleFarsi": "منزل ما در شهر واقع شده است.", "examplePronunciation": "manzel-e mā dar shahr vāqe' shodah ast.", "exampleEnglish": "Our house is located in the city."},
    {"word": "عمده", "pronunciation": "omdah", "meaning": "majority, bulk, wholesale", "exampleFarsi": "اینجا مرکز خرید عمده است.", "examplePronunciation": "injā markaz-e kharid-e omdah ast.", "exampleEnglish": "This is a wholesale shopping center."},
    {"word": "تلفن", "pronunciation": "telefon", "meaning": "telephone", "exampleFarsi": "لطفاً با تلفن تماس بگیرید.", "examplePronunciation": "lotfan baa telefon tamas begirid", "exampleEnglish": "Please contact me by phone."},
    {"word": "وخیم", "pronunciation": "vakhim", "meaning": "severe, serious", "exampleFarsi": "وضعیت وخیم بیماری او بهبود پیدا نکرد.", "examplePronunciation": "vaz'eiyat-e vakhim-e bimari-ye u behbood payda nakard", "exampleEnglish": "The patient's condition remained severe and did not improve."},
    {"word": "سواد", "pronunciation": "sawad", "meaning": "literacy, education", "exampleFarsi": "افزایش سواد جامعه می‌تواند از بسیاری از مشکلات اجتماعی جلوگیری کند.", "examplePronunciation": "afzaayesh-e savad-e jaame'a metawanad az besyaari az moshkelaat-e ejtemaaii jolaw giri konad", "exampleEnglish": "Increasing literacy in society can prevent many social problems."},
    {"word": "پنیر", "pronunciation": "panir", "meaning": "cheese", "exampleFarsi": "صبحانه من شامل نان و پنیر بود.", "examplePronunciation": "sobhanah-e man shamel-e naan-o panir bud", "exampleEnglish": "My breakfast consists of bread and cheese."},
    {"word": "فعال", "pronunciation": "fa'al", "meaning": "active, activist", "exampleFarsi": "این سازمان یک سازمان فعال در حوزهٔ حقوق بشر است.", "examplePronunciation": "in sazmān yak sazmān-e fa'al dar howza-ye hoqooq-e bashar ast", "exampleEnglish": "This organization is an active organization in the field of human rights."},
    {"word": "کوچک", "pronunciation": "kochak", "meaning": "small, little", "exampleFarsi": "دستهٔ کوچکی از گل‌ها را به من هدیه داد.", "examplePronunciation": "dasta-ye kochak-i az gol-hā raa be man hadiya dād", "exampleEnglish": "They gave me a small bunch of flowers as a gift."},
    {"word": "کورس", "pronunciation": "kors", "meaning": "course, curriculum, program", "exampleFarsi": "من کورس زبان می‌خوانم.", "examplePronunciation": "Man kors-e zabān mikhwanam.", "exampleEnglish": "I am taking a language course."},
    {"word": "تعهد", "pronunciation": "ta'ahhud", "meaning": "commitment, promise, pledge", "exampleFarsi": "او به تعهد خود درباره‌ی مسئله صحبت کرد.", "examplePronunciation": "oo ba ta'ahhud-e khod dar-bāre-ye mas'alah sohbat kard.", "exampleEnglish": "He spoke with commitment about the issue."},
    {"word": "نیمه", "pronunciation": "nima", "meaning": "half, semi", "exampleFarsi": "نیمه شب باید خوابید.", "examplePronunciation": "Nima-e shab bāyad khābid.", "exampleEnglish": "You have to go to sleep at midnight."},
    {"word": "خنده", "pronunciation": "khānda", "meaning": "laughter, smile", "exampleFarsi": "او با خنده به من پاسخ داد.", "examplePronunciation": "oo bā khānda be man pāsokh dād.", "exampleEnglish": "He answered me with a smile."},
    {"word": "خوشی", "pronunciation": "khoshi", "meaning": "happiness, joy", "exampleFarsi": "با خوشی به آینده نگاه می‌کنم.", "examplePronunciation": "Bā khoshi ba āyanda negāh mekonam.", "exampleEnglish": "I look forward to the future with happiness."},
    {"word": "تکان", "pronunciation": "takān", "meaning": "shock, jolt, shaking", "exampleFarsi": "حادثه باعث تکان خوردن همه شد.", "examplePronunciation": "Hādesa bā'es takān khordan-e hamah shod.", "exampleEnglish": "The accident caused everyone to get shocked."},
    {"word": "گرچه", "pronunciation": "garcheh", "meaning": "although, even though", "exampleFarsi": "گرچه خیلی سرماست، من بیرون می‌روم.", "examplePronunciation": "garche kheli sarma-st, man birun mirawam.", "exampleEnglish": "Although it's very cold, I'm going out."},
    {"word": "مبهم", "pronunciation": "mobham", "meaning": "ambiguous, vague", "exampleFarsi": "متن این مقاله بسیار مبهم است و مخاطب را به خود نمی‌کشاند.", "examplePronunciation": "matn-e in maqala basiyar mobham ast wa mokhatab ra ba khod namikashand.", "exampleEnglish": "The text of this article is very ambiguous and doesn't attract the reader."},
    {"word": "زمین", "pronunciation": "zamin", "meaning": "earth, ground, land", "exampleFarsi": "ما به زمین نیاز داریم تا بتوانیم زندگی کنیم.", "examplePronunciation": "ma ba zamin naiaz darim ta betawanim zendagi konim.", "exampleEnglish": "We need earth to be able to live."},
    {"word": "قومی", "pronunciation": "qawmi", "meaning": "national, ethnic", "exampleFarsi": "هر قومی خصوصیات ویژه‌ای دارد که آن را از دیگران متمایز می‌کند.", "examplePronunciation": "har qawmi khosusiat-e vizha-i darad ke an ra az digaran motemayez mikonad.", "exampleEnglish": "Every ethnic group has special characteristics that distinguish it from others."},
    {"word": "شاهد", "pronunciation": "shahed", "meaning": "witness, testimony", "exampleFarsi": "شاهدان می‌گویند که در آنجا اتفاقی نیفتاده است.", "examplePronunciation": "shahed-an megoyand ke dar anja ettefaqi naioftadah ast.", "exampleEnglish": "Witnesses say that nothing happened there."},
    {"word": "تنگی", "pronunciation": "tang-i", "meaning": "narrowness, tightness, difficulty", "exampleFarsi": "او در تنگی روزگار بود.", "examplePronunciation": "oo dar tang-i rozgar bud", "exampleEnglish": "He was in a difficult situation."}, 
    {"word": "عینک", "pronunciation": "aynak", "meaning": "glasses, spectacles", "exampleFarsi": "من نیاز به عینک دارم.", "examplePronunciation": "man niyaz be aynak daram", "exampleEnglish": "I need glasses."},
    {"word": "تمام", "pronunciation": "tamam", "meaning": "all, complete, entire", "exampleFarsi": "همه برای کار تمام تلاش خود را می کنند.", "examplePronunciation": "hamah baraye kar tamame talashe khod ra mekonand", "exampleEnglish": "Everyone is making their best effort for the job."},
    {"word": "دشمن", "pronunciation": "doshman", "meaning": "enemy, foe", "exampleFarsi": "او با دشمنانش مبارزه کرد.", "examplePronunciation": "oo ba doshman-anash mobarez-e kard", "exampleEnglish": "He fought against his enemies."},
    {"word": "لوحه", "pronunciation": "lowha", "meaning": "board, panel, tablet", "exampleFarsi": "لوحه‌ای روی دیوار نصب شده بود.", "examplePronunciation": "lowha-e rooye dewar nasb shoda bud", "exampleEnglish": "A board was installed on the wall."},
    {"word": "چهار", "pronunciation": "chahâr", "meaning": "four", "exampleFarsi": "ما چهار نفر هستیم.", "examplePronunciation": "mâ chahâr nafar hastim", "exampleEnglish": "We are four people."},
    {"word": "بیست", "pronunciation": "bist", "meaning": "twenty", "exampleFarsi": "امروز بیستم دیسامبر است.", "examplePronunciation": "Emrooz bistome desambar ast.", "exampleEnglish": "Today is the twentieth of December."},
    {"word": "حشره", "pronunciation": "hasharah", "meaning": "insect", "exampleFarsi": "حشرات را باید از خانه دور نگه داشت.", "examplePronunciation": "Hasharaat ra baayad az khana door negah daasht.", "exampleEnglish": "Insects should be kept away from the house."},
    {"word": "چاره", "pronunciation": "charah", "meaning": "solution, remedy", "exampleFarsi": "هنوز چاره‌ای برای این مشکل پیدا نکردم.", "examplePronunciation": "Hanooz charaei baraye in moshkel payda nakardam.", "exampleEnglish": "I haven't found a solution for this problem yet."},
    {"word": "بیجا", "pronunciation": "beja", "meaning": "useless, futile", "exampleFarsi": "وقتی بیجا کاری می‌کنیم، فقط وقت خود را تلف می‌کنیم.", "examplePronunciation": "Vaqti beja kari mekonim, faqat vaqte khod ra talaaf mekonim.", "exampleEnglish": "When we do something useless, we're just wasting our time."},
    {"word": "تحمل", "pronunciation": "tahammol", "meaning": "tolerance, endurance", "exampleFarsi": "تحمل درد یکی از صفات بزرگ است.", "examplePronunciation": "Tahammol-e dard yaki az sefat-e bozorg ast.", "exampleEnglish": "Endurance of pain is one of the great virtues."},
    {"word": "قبول", "pronunciation": "qabool", "meaning": "acceptance, admission", "exampleFarsi": "بعد از چند بار رد شدن، نهایتا قبول شد.", "examplePronunciation": "Bad az chand baar rad shodan, nahayatan qabool shod.", "exampleEnglish": "After being rejected a few times, it was finally accepted."},
    {"word": "جمعه", "pronunciation": "jom'a", "meaning": "Friday", "exampleFarsi": "ما هر جمعه به خانه‌ی پدربزرگمان می‌رویم.", "examplePronunciation": "mā har jom'a ba khāna-ye padar bozorg-e mān merawim.", "exampleEnglish": "We go to our grandfather's house every Friday."},
    {"word": "نمره", "pronunciation": "nomra", "meaning": "grade, score", "exampleFarsi": "این تمرین نمره‌ی خوبی برای شما خواهد داشت.", "examplePronunciation": "in tamrin nomra-ye khub-i barā-ye shomā khāhad dāsht.", "exampleEnglish": "This exercise will have a good grade for you."},
    {"word": "لرزش", "pronunciation": "larzesh", "meaning": "tremble, tremor", "exampleFarsi": "زمین در زلزله شدید لرزش داشت.", "examplePronunciation": "zamin dar zelzela-ye shadid larzesh dāsht.", "exampleEnglish": "The ground trembled in the severe earthquake."},
    {"word": "ظریف", "pronunciation": "zarif", "meaning": "elegant, delicate, subtle", "exampleFarsi": "او به گفتار ظریف و حساسیت خاصی دارد.", "examplePronunciation": "oo ba goftār zarif-o hesāsīyat-e khās-i dārad.", "exampleEnglish": "He has a special elegance and sensitivity in his speech."},
    {"word": "مجدد", "pronunciation": "mojaddad", "meaning": "again, anew, afresh", "exampleFarsi": "پس از اتمام نسخه اول، به مجدد شروع به کار کردیم.", "examplePronunciation": "pas az etmām-e noskh-e awal, be mojaddad shoro ba kār kardim.", "exampleEnglish": "After finishing the first version, we started again."},
    {"word": "حاکم", "pronunciation": "hākem", "meaning": "ruler, governor, ruler, sovereign", "exampleFarsi": "حاکم شهر در نشست خبری با خبرنگاران گفتگو کرد.", "examplePronunciation": "hākem-e shahr dar neshast-e khabari bā khabarnegarān goftogo kard.", "exampleEnglish": "The governor of the city spoke with reporters at the press conference."},
    {"word": "نیست", "pronunciation": "nist", "meaning": "nonexistent; not here, absent", "exampleFarsi": "من خیلی می‌ترسم که نیستی", "examplePronunciation": "man kheli matarsam ke nesti", "exampleEnglish": "I'm very afraid that you're not here"},
    {"word": "قشنگ", "pronunciation": "qashang", "meaning": "beautiful, pretty", "exampleFarsi": "آنها زوج قشنگی اند", "examplePronunciation": "aanha zawje qashangi and", "exampleEnglish": "They are a beautiful couple"},
    {"word": "کدام", "pronunciation": "kodam", "meaning": "which", "exampleFarsi": "کدام کتاب است؟", "examplePronunciation": "kodam ketab ast?", "exampleEnglish": "Which book is it?"},
    {"word": "بومی", "pronunciation": "bumi", "meaning": "native, indigenous; local", "exampleFarsi": "اینجا موسیقی بومی خوبی دارد", "examplePronunciation": "injā musiqi-e bumi khubi dārad", "exampleEnglish": "There's good local music here."},
    {"word": "خواص", "pronunciation": "khawās", "meaning": "properties, characteristics, attributes", "exampleFarsi": "خواص پیاز سفید چیست؟", "examplePronunciation": "khawās-e payāz-e safid chist?", "exampleEnglish": "What are the properties of white onions?"},
    {"word": "عتیق", "pronunciation": "atiq", "meaning": "ancient, antique, old, archaic", "exampleFarsi": "این یک عتیقه قدیمی است", "examplePronunciation": "in yak atiqe qadimi ast", "exampleEnglish": "This is an old antique."},
    {"word": "ردیف", "pronunciation": "radif", "meaning": "row, line, rank, file", "exampleFarsi": "همه دانشجویان در ردیف‌های خود قرار گرفتند", "examplePronunciation": "hama dāneshjoyān dar radif-hā-ye khod qārar gereftand", "exampleEnglish": "All students lined up in their rows."},
    {"word": "حیاط", "pronunciation": "hayat", "meaning": "courtyard, yard, patio", "exampleFarsi": "در حیاط خلوت خانه خود ایستاد.", "examplePronunciation": "dar heyat-e khalwat-e khanah-e khod istad.", "exampleEnglish": "He stood in the secluded courtyard of his house."},
    {"word": "دفتر", "pronunciation": "daftar", "meaning": "office, bureau, notebook", "exampleFarsi": "او در یک دفتر خاطرات مشغول نوشتن شده بود.", "examplePronunciation": "Oo dar yak daftar-e khaterat mashghol-e neweshtan shoda bood.", "exampleEnglish": "He was busy writing in a notebook of memoirs."},
    {"word": "هرجا", "pronunciation": "harja", "meaning": "anywhere, wherever", "exampleFarsi": "هرجا که بروی، این مشکل با توست.", "examplePronunciation": "harja ke berawi, in moshkel ba tost.", "exampleEnglish": "Wherever you go, this problem goes with you."},
    {"word": "چشمه", "pronunciation": "cheshmah", "meaning": "spring, fountain", "exampleFarsi": "او در کنار چشمه بزرگ نشسته بود.", "examplePronunciation": "oo dar kenar-e cheshmah-e bozorg neshasta bood.", "exampleEnglish": "He was sitting next to a big fountain."},
    {"word": "جعلی", "pronunciation": "ja'li", "meaning": "fake, counterfeit", "exampleFarsi": "این پول ها جعلی هستند.", "examplePronunciation": "in pul-ha ja'li hastand.", "exampleEnglish": "These bills are fake."},
    {"word": "صدمه", "pronunciation": "sadamah", "meaning": "harm, damage, injury", "exampleFarsi": "این عمل برایش صدمه رساند.", "examplePronunciation": "in amal barayash sedamah rasand.", "exampleEnglish": "This action caused harm to him."},
    {"word": "حیرت", "pronunciation": "hayrat", "meaning": "amazement, astonishment, wonder", "exampleFarsi": "او از حیرت به شدت از هوش در آمد.", "examplePronunciation": "oo az hayrat ba shadat az hosh dar amad", "exampleEnglish": "He was so amazed that he passed out."},
    {"word": "جهان", "pronunciation": "jahān", "meaning": "world, universe", "exampleFarsi": "همه ما در این جهان زندگی می کنیم.", "examplePronunciation": "hama-ye mā dar in jahān zendagi mekonim", "exampleEnglish": "We all live in this world."},
    {"word": "کامل", "pronunciation": "kāmel", "meaning": "perfect, complete", "exampleFarsi": "این شعر به نظر من کامل است.", "examplePronunciation": "in she'r ba nazar-e man kāmel ast", "exampleEnglish": "This poem, in my opinion, is perfect."},
    {"word": "کنار", "pronunciation": "kenār", "meaning": "beside, next to", "exampleFarsi": "من در پارک کنار درخت نشسته بودم.", "examplePronunciation": "man dar pārk kenār-e darakht neshasta boodam", "exampleEnglish": "I was sitting next to a tree in the park."},
    {"word": "ماسک", "pronunciation": "māsk", "meaning": "mask", "exampleFarsi": "وقتی در کوچه قدم می زنم، ماسک می زنم.", "examplePronunciation": "vaqti dar kuchah qadam mezanam, māsk mizanam", "exampleEnglish": "When I walk on the street, I wear a mask."},
    {"word": "خزان", "pronunciation": "khazān", "meaning": "autumn, fall", "exampleFarsi": "در خزان، برگ های درختان زرد و قرمز می شوند.", "examplePronunciation": "dar khazān, barg-hāye darakhtān zard-o qermez mishawand", "exampleEnglish": "In the fall, the leaves of trees turn yellow and red."},
    {"word": "پیری", "pronunciation": "piri", "meaning": "old age", "exampleFarsi": "بعضی از مردم از پیری می ترسند.", "examplePronunciation": "ba'zi az mardom az piri metarsand", "exampleEnglish": "Some people are afraid of old age."},
    {"word": "کوچه", "pronunciation": "kuchah", "meaning": "alley", "exampleFarsi": "من در کوچه زندگی می‌کنم", "examplePronunciation": "man dar kuchah zendagi mekonam", "exampleEnglish": "I live in an alley."},
    {"word": "مردم", "pronunciation": "mardom", "meaning": "people", "exampleFarsi": "مردم افغانستان بسیار مهمان‌نوازند", "examplePronunciation": "mardome afghaanestan basiar mehmaan-nawaz and", "exampleEnglish": "People of Afghanistan are very hospitable."},
    {"word": "مثبت", "pronunciation": "mosbat", "meaning": "positive", "exampleFarsi": "باید به مثبت‌های زندگی فکر کنیم", "examplePronunciation": "bayad ba mosbat-haye zendagi fekr konim", "exampleEnglish": "We should think about the positive things in life."},
    {"word": "گندم", "pronunciation": "gandom", "meaning": "wheat", "exampleFarsi": "گندم به‌عنوان غذای اصلی در بسیاری از کشورها مصرف می‌شود", "examplePronunciation": "gandom ba-onwan-e ghaza-ye asli dar basiyari az keshwar-ha masraf mishawad", "exampleEnglish": "Wheat is consumed as a staple food in many countries."},
    {"word": "خروس", "pronunciation": "khorus", "meaning": "rooster", "exampleFarsi": "صبح‌ها صدای خروس‌ها خیلی خوب است", "examplePronunciation": "sobh-ha sadaye khorus-ha kheli khub ast", "exampleEnglish": "The sound of roosters in the morning is very nice."},
    {"word": "هندی", "pronunciation": "hendi", "meaning": "Indian", "exampleFarsi": "من یک دوست هندی دارم", "examplePronunciation": "man yak dost-e hendi dar-am", "exampleEnglish": "I have an Indian friend."},
    {"word": "شبیه", "pronunciation": "shabihe", "meaning": "similar", "exampleFarsi": "این دو قلعه شبیه هم هستند", "examplePronunciation": "in do qol'ah shabiye ham hastand", "exampleEnglish": "These two castles are similar to each other."},
    {"word": "تقلب", "pronunciation": "taqalob", "meaning": "cheating", "exampleFarsi": "او تقلب کرد و جواب امتحان را دزدید.", "examplePronunciation": "Oo taqalob kard wa javabe emtehan ra dozdíd.", "exampleEnglish": "He cheated and stole the exam answer."},
    {"word": "ماست", "pronunciation": "mast", "meaning": "yogurt", "exampleFarsi": "من صبحانه ماست با شیرینی می‌خورم.", "examplePronunciation": "man sobhana mast ba shirini mekhoram.", "exampleEnglish": "I eat yogurt with pastry for breakfast."},
    {"word": "خرما", "pronunciation": "khorma", "meaning": "date (fruit)", "exampleFarsi": "خرما خیلی خوشمزه است.", "examplePronunciation": "khorma kheli khoshmaza ast.", "exampleEnglish": "Dates are very tasty."},
    {"word": "پلنگ", "pronunciation": "palang", "meaning": "panther, leopard", "exampleFarsi": "پلنگ ها با خودکامگی بسیاری همراهند.", "examplePronunciation": "palang-ha bā khod-kāmagi besyārī hamrāh-and.", "exampleEnglish": "Panthers are very independent."},
    {"word": "سفید", "pronunciation": "safid", "meaning": "white", "exampleFarsi": "این کتاب جلد سفید دارد.", "examplePronunciation": "īn ketāb jeld-e safid dārad.", "exampleEnglish": "This book has a white cover."},
    {"word": "عملی", "pronunciation": "amalī", "meaning": "practical", "exampleFarsi": "آموزش های عملی برای آینده کاری مهم هستند.", "examplePronunciation": "āmūzesh-hāye amalī barāye āyande-e kārī mohem hastand.", "exampleEnglish": "Practical trainings are important for future career."},
    {"word": "وصیت", "pronunciation": "wasiyat", "meaning": "bequest, will", "exampleFarsi": "او وصیت‌نامه‌اش را برای خانواده نوشت.", "examplePronunciation": "oo wasiyat-namah-ash rā barā-ye khānawādah nawesht.", "exampleEnglish": "He wrote his will for the family."},
    {"word": "کلاس", "pronunciation": "kelas", "meaning": "class, course", "exampleFarsi": "امروز کلاس ریاضیات را دوست نداشتم.", "examplePronunciation": "emruz kelās-e riyāziyāt rā dust nadāshtam.", "exampleEnglish": "Today, I didn't like the math class."},
    {"word": "علمی", "pronunciation": "elmi", "meaning": "scientific, academic", "exampleFarsi": "مقاله‌ای در مجله علمی منتشر شده است.", "examplePronunciation": "maqāle-i dar majale-ye elmi montasher shodah ast.", "exampleEnglish": "An article has been published in a scientific journal."},
    {"word": "گریز", "pronunciation": "gorez", "meaning": "escape, flight", "exampleFarsi": "همه جاها گریزن", "examplePronunciation": "hama jahā gorezan", "exampleEnglish": "They are fleeing everywhere."},
    {"word": "سورت", "pronunciation": "surat", "meaning": "face, appearance", "exampleFarsi": "در حال حاضر نمی توانیم سورت همه را ببینیم", "examplePronunciation": "dar hāle hāzer nami-tawanim surate hama rā bibinim", "exampleEnglish": "We can't see everyone's face now."},
    {"word": "ساخت", "pronunciation": "sākht", "meaning": "construction, production, creation", "exampleFarsi": "ساخت خانه زمان برداشته است", "examplePronunciation": "sākhte khāne zamān bar dāshta ast", "exampleEnglish": "Building a house is taking time."},
    {"word": "مردد", "pronunciation": "mordad", "meaning": "hesitant, doubtful, uncertain", "exampleFarsi": "من در مورد این موضوع مرددم", "examplePronunciation": "man dar mawrede in mawzo mordadam", "exampleEnglish": "I'm hesitant about this issue."},
    {"word": "دفعه", "pronunciation": "dafa", "meaning": "time, occasion", "exampleFarsi": "این دفعه باید برنامه ها را جدی گرفت", "examplePronunciation": "in dafā bayad barnāmahā rā jeddi gereft", "exampleEnglish": "This time we have to take the plans seriously."},
    {"word": "حالا", "pronunciation": "hālā", "meaning": "now, at this time", "exampleFarsi": "حالا می روم خانه", "examplePronunciation": "hālā merawam khāna", "exampleEnglish": "I'm going home now."},
    {"word": "مجرم", "pronunciation": "mojrem", "meaning": "criminal, offender", "exampleFarsi": "مجرمان باید مجازات شوند", "examplePronunciation": "mojremān bāyad mojāzāt shawand", "exampleEnglish": "Criminals must be punished."},
    {"word": "نظام", "pronunciation": "nezam", "meaning": "system, order", "exampleFarsi": "نظام سلطنتی", "examplePronunciation": "nezam-e saltanati", "exampleEnglish": "monarchical system"},
    {"word": "گوشه", "pronunciation": "gosha", "meaning": "corner, edge", "exampleFarsi": "گوشه و کنار", "examplePronunciation": "gosha o kenar", "exampleEnglish": "every nook and cranny"},
    {"word": "شرقی", "pronunciation": "sharqi", "meaning": "eastern", "exampleFarsi": "کشورهای شرقی", "examplePronunciation": "keshwar-haye sharghi", "exampleEnglish": "eastern countries"},
    {"word": "پنجم", "pronunciation": "panjom", "meaning": "fifth", "exampleFarsi": "روز پنجم", "examplePronunciation": "roz-e panjom", "exampleEnglish": "fifth day"},
    {"word": "قطره", "pronunciation": "qatra", "meaning": "drop", "exampleFarsi": "قطرات آب", "examplePronunciation": "qatarat-e ab", "exampleEnglish": "water drops"},
    {"word": "تماس", "pronunciation": "tamās", "meaning": "contact; connection; communication", "exampleFarsi": "من باید با او تماس بگیرم.", "examplePronunciation": "man bāyad bā u tamās begiram.", "exampleEnglish": "I need to contact him."},
    {"word": "جاهل", "pronunciation": "jāhel", "meaning": "ignorant, uneducated", "exampleFarsi": "باید تلاش کنیم از جاهلیت خود رهایی پیدا کنیم.", "examplePronunciation": "bāyad talāsh konim az jāheliyat-e khod rahāyi paydā konim.", "exampleEnglish": "We must strive to free ourselves from our ignorance."},
    {"word": "کهنه", "pronunciation": "kohna", "meaning": "old, ancient", "exampleFarsi": "در این موزه بسیاری از کتاب‌ها کهنه و باستانی هستند.", "examplePronunciation": "dar in mozah besyāri az ketābhā-ye kohna wa bāstāni hastand.", "exampleEnglish": "In this museum, many of the books are old and ancient."},
    {"word": "شوهر", "pronunciation": "shohar", "meaning": "husband", "exampleFarsi": "شوهر من داکتر است.", "examplePronunciation": "shohar-e man dactar ast.", "exampleEnglish": "My husband is a doctor."},
    {"word": "تلفظ", "pronunciation": "talaffoz", "meaning": "pronunciation", "exampleFarsi": "تلفظ درست کلمات در یادگیری زبان بسیار مهم است.", "examplePronunciation": "talaffoz-e dorost-e kalemat dar yadgiri-ye zaban besyar mohem ast.", "exampleEnglish": "Proper pronunciation of words is very important in language learning."},
    {"word": "سهام", "pronunciation": "saham", "meaning": "stock, share", "exampleFarsi": "او سهام خود را در شرکت فروش داد.", "examplePronunciation": "u saham-e khod ra dar sherkat forush dad.", "exampleEnglish": "He sold his shares in the company."},
    {"word": "کشتی", "pronunciation": "keshti", "meaning": "ship, boat", "exampleFarsi": "بهترین روش برای دیدن جزایر نزدیک با کشتی است.", "examplePronunciation": "behtarin rawesh baraye didan-e jazayer-e nazdik ba keshti ast.", "exampleEnglish": "The best way to see nearby islands is by boat."},
    {"word": "مربی", "pronunciation": "morabbi", "meaning": "coach", "exampleFarsi": "مربی تیم فوتبال", "examplePronunciation": "morabbi-ye tim-e futbal", "exampleEnglish": "football team coach"},
    {"word": "رفتن", "pronunciation": "raftan", "meaning": "to go", "exampleFarsi": "من به مکتب رفتم", "examplePronunciation": "man be maktab raftam", "exampleEnglish": "I went to school"},
    {"word": "همدم", "pronunciation": "hamdam", "meaning": "companion", "exampleFarsi": "همدم من در سفر", "examplePronunciation": "hamdam-e man dar safar", "exampleEnglish": "my companion in the journey"},
    {"word": "حمام", "pronunciation": "hamam", "meaning": "bathhouse", "exampleFarsi": "بعد از بازی فوتبال به حمام رفتم", "examplePronunciation": "ba'd az bazi-ye futbal ba hamam raftam", "exampleEnglish": "I went to the bathhouse after the football game"},
    {"word": "کمان", "pronunciation": "kaman", "meaning": "bow", "exampleFarsi": "کمان بازی یکی از ورزش های محبوب در کشور ماست", "examplePronunciation": "kaman-bazi yaki az warzesh-haye mahboob dar keshwar-e ma-st", "exampleEnglish": "archery is one of the popular sports in our country"},
    {"word": "پرچم", "pronunciation": "parcham", "meaning": "flag", "exampleFarsi": "پرچم کشور من", "examplePronunciation": "parcham-e keshwar-e man", "exampleEnglish": "my country's flag"},
    {"word": "ورزش", "pronunciation": "صarzesh", "meaning": "sport", "exampleFarsi": "من عاشق ورزش های تیمی هستم.", "examplePronunciation": "man 'ashq-e warzesh-haye tīmī hastam", "exampleEnglish": "I love team sports."},
    {"word": "رسام", "pronunciation": "rasām", "meaning": "painter", "exampleFarsi": "محمد نقاش خوبی است، او رسام حرفه ای است.", "examplePronunciation": "mohammad naqāsh-e khub ast, u resām-e ḥerfayi ast", "exampleEnglish": "Mohammad is a good painter, he's a professional artist."},
    {"word": "مجله", "pronunciation": "majale", "meaning": "magazine", "exampleFarsi": "من هر هفته چند مجله تحقیقاتی می خوانم.", "examplePronunciation": "man har hafta chand mojale-ye tahqiqāti mikhanam", "exampleEnglish": "I read a few scientific magazines every week."},
    {"word": "جذبه", "pronunciation": "jazaba", "meaning": "attraction", "exampleFarsi": "جذبه میان دو نفر یکی از شرایط مهم برای شکل گیری رابطه است.", "examplePronunciation": "jazaba miyāne do nafar", "exampleEnglish": "Attraction between two people."},
    {"word": "شعبه", "pronunciation": "sho'ba", "meaning": "branch", "exampleFarsi": "این شرکت دارای شعبه های زیادی در سراسر جهان است.", "examplePronunciation": "in sherkat dārā-ye sho'ba-hāye ziādi dar sarāsar-e jahān ast", "exampleEnglish": "This company has many branches around the world."},
    {"word": "شخصی", "pronunciation": "shakhsi", "meaning": "personal", "exampleFarsi": "این مشکل شخصی من است.", "examplePronunciation": "in moshkel-e shakhsi-e man ast", "exampleEnglish": "This is my personal problem."},
    {"word": "عینک", "pronunciation": "aynak", "meaning": "glasses", "exampleFarsi": "من برای دیدن بهتر باید عینک بزنم.", "examplePronunciation": "man baraye didan behtar bayad aynak bezanam", "exampleEnglish": "I need to wear glasses to see better."},
    {"word": "معنی", "pronunciation": "ma'ni", "meaning": "meaning", "exampleFarsi": "معنی این کلمه چیست؟", "examplePronunciation": "ma'ni-e in kalema chist?", "exampleEnglish": "What is the meaning of this word?"},
    {"word": "ماده", "pronunciation": "madda", "meaning": "material", "exampleFarsi": "این ماده برای ساخت محصول مورد نیاز است.", "examplePronunciation": "in madda baraye sakht-e mahsul-e mawrde-nayaz ast", "exampleEnglish": "This material is necessary for making the product."},
    {"word": "خلاص", "pronunciation": "khalās", "meaning": "summary", "exampleFarsi": "لطفاً یک خلاصه از این متن برای من بفرستید.", "examplePronunciation": "lotfan yak kholasa-ye in matn baraye man befrestid", "exampleEnglish": "Please send me a summary of this text."},
    {"word": "مایه", "pronunciation": "māyah", "meaning": "subject, theme, cause", "exampleFarsi": "آینده کشور، از مایه‌های مبهم برای بسیاری از افراد است.", "examplePronunciation": "āyanda-ye keshwar, az māyahā-ye mobham barā-ye bisyar-e afrād ast.", "exampleEnglish": "The future of the country is one of the ambiguous subjects for many people."},
    {"word": "متهم", "pronunciation": "mota'ham", "meaning": "suspect, accused", "exampleFarsi": "پس از بازجویی، متهم اعتراف کرد.", "examplePronunciation": "pas az bāzjuyi, mota'ham eteraf kard.", "exampleEnglish": "After interrogation, the accused confessed."},
    {"word": "پناه", "pronunciation": "panāh", "meaning": "refuge, shelter", "exampleFarsi": "مهمان‌نوازی و پناه دادن به دیگران نشانه‌ای از بزرگواری است.", "examplePronunciation": "mehmān-nawāzi wa panāh dādan ba digarān neshāna-i az bozorgwāri ast.", "exampleEnglish": "Hospitality and providing refuge to others is a sign of generosity."},
    {"word": "صادق", "pronunciation": "sādeq", "meaning": "honest, sincere, truthful", "exampleFarsi": "صداقت یکی از ارزش‌های مهم در هر رابطه اجتماعی است.", "examplePronunciation": "sadāqat yaki az arzeshhā-ye mohem dar har rābeta-e ejtemā'i ast.", "exampleEnglish": "Honesty is one of the important values in any social relationship."},
    {"word": "قالب", "pronunciation": "qāleb", "meaning": "mold, framework, form", "exampleFarsi": "بیان موضوعات در یک قالب منظم.", "examplePronunciation": "byān-e mawzo'āt dar yak qaleb-e monazam", "exampleEnglish": "Presenting topics in a structured format."},
    {"word": "وقفه", "pronunciation": "vaqfa", "meaning": "pause, break, interruption", "exampleFarsi": "بعد از چند ساعت رانندگی، برای گرفتن وقفه کوتاهی در کنار جاده متوقف شدیم.", "examplePronunciation": "bad az chand saat rānandagi, barāye gereftan-e vaqfa-ye kotāhi dar kenār-e jāda motawaqef shodim.", "exampleEnglish": "After a few hours of driving, we stopped by the side of the road for a short break."},
    {"word": "لوحه", "pronunciation": "lawha", "meaning": "tablet, board, plaque", "exampleFarsi": "لوحه‌های نامزدهای انتخابات در سراسر شهر نصب شدند.", "examplePronunciation": "lawha-hāye namzad-hā-ye entekhābāt dar sarāsar-e shahr nasb shodand.", "exampleEnglish": "Election candidate boards were installed throughout the city."},
    {"word": "معما", "pronunciation": "ma'mā", "meaning": "puzzle, riddle", "exampleFarsi": "این معما برای خیلی از افراد چالش برانگیز است.", "examplePronunciation": "in ma'mā barāye kheli az afrād chālesh barāngiz ast.", "exampleEnglish": "This puzzle is challenging for many people."},
    {"word": "جناب", "pronunciation": "jenāb", "meaning": "sir, madam, Mr., Mrs.", "exampleFarsi": "جناب آقای شفیعی فرمانده ما هستند.", "examplePronunciation": "jonāb-e āqā-ye shafiei farmāndeh-e mā hastand.", "exampleEnglish": "Mr. Shafiei is our commander, sir."},
    {"word": "خالص", "pronunciation": "khāles", "meaning": "pure, net, clear", "exampleFarsi": "درآمد خالص این ماه بسیار بیشتر از معمول بود.", "examplePronunciation": "darāmad-e khāles-e in māh besyār bish-tar az ma'mul bud.", "exampleEnglish": "Net income this month was much higher than usual."},
    {"word": "رفیق", "pronunciation": "rafiq", "meaning": "companion, friend, mate", "exampleFarsi": "بهترین رفیق من دوست صمیمی‌ام است.", "examplePronunciation": "behtarin refiq-e man dust-e samimi-am ast.", "exampleEnglish": "My best companion is my close friend."},
    {"word": "معبد", "pronunciation": "ma'bad", "meaning": "temple, shrine", "exampleFarsi": "گردشگران زیادی به معبد بودایی این شهر می‌آیند.", "examplePronunciation": "gardeshgaran-e ziyaad-i ba ma'bad-e budaayi-ye in shahr mi-aayand", "exampleEnglish": "Many tourists come to this city's Buddhist temple."},
    {"word": "کافی", "pronunciation": "kafi", "meaning": "enough, sufficient", "exampleFarsi": "پذیرایی از مهمان‌ها.", "examplePronunciation": "paziraa'i az mehman-haa", "exampleEnglish": "reception of guests"},
    {"word": "سقوط", "pronunciation": "soqoot", "meaning": "fall, collapse", "exampleFarsi": "ساختمان قدیمی شهر به دلیل عدم تعمیر و نگهداری، به زودی قرار است به سقوط بیفتد.", "examplePronunciation": "saakhtemaan-e qadimi-ye shahr ba dalil-e 'adam-e ta'mir wa negahdaari, ba zoodi qarar ast ba soqoot bi-oftad", "exampleEnglish": "Due to lack of maintenance, the old city building is soon to collapse."},
    {"word": "خصلت", "pronunciation": "khesalat", "meaning": "trait, characteristic", "exampleFarsi": "خصلت‌های بدی که در او بود، باعث شد که دوستانش از او دور شوند.", "examplePronunciation": "khesalat-haa-ye badi ke dar-u bud, baa'es shod ke dostaanash az-u door shawand", "exampleEnglish": "The bad traits he had caused his friends to stay away from him."},
    {"word": "واضح", "pronunciation": "waazeh", "meaning": "clear, obvious", "exampleFarsi": "برخی از ادعاها از نظر قانونی واضح نیستند و نیاز به بررسی بیشتری دارند.", "examplePronunciation": "bar-khi az ed'aa-haa az nazar-e qaanuni waazeh nestan-d wa naiaaz ba barrasi beshtar-i daarand", "exampleEnglish": "Some claims are not clearly defined from a legal point of view and require further investigation."},
    {"word": "قامت", "pronunciation": "qaamat", "meaning": "stature, height", "exampleFarsi": "قامت بلندی دارید.", "examplePronunciation": "qaamat-e bolandi daarid.", "exampleEnglish": "You have a tall stature."},
    {"word": "راست", "pronunciation": "raast", "meaning": "right, correct", "exampleFarsi": "جواب راست را نخستین بار گفتید.", "examplePronunciation": "jawab-e raast ra nokhostin baar goftid.", "exampleEnglish": "You gave the right answer on the first try."},
    {"word": "خلوت", "pronunciation": "khalwat", "meaning": "solitude", "exampleFarsi": "او برای خلوت کردن به کوه رفت.", "examplePronunciation": "oo baraye khalwat kardan ba kuh raft.", "exampleEnglish": "He went to the mountain for solitude."},
    {"word": "غیبت", "pronunciation": "ghaybat", "meaning": "absence, disappearance, hiding", "exampleFarsi": "غیبت او برای مدتی طولانی بود.", "examplePronunciation": "ghaybat oo baraye moddati tolani bud.", "exampleEnglish": "His disappearance was for a long time."},
    {"word": "دلیل", "pronunciation": "daleel", "meaning": "reason, evidence, proof", "exampleFarsi": "دلیل دانشمندان برای این پدیده.", "examplePronunciation": "daleel-e daneshmandan baraye in padeedah.", "exampleEnglish": "Scientists' evidence for this phenomenon."},
    {"word": "خطری", "pronunciation": "khatari", "meaning": "risky, dangerous", "exampleFarsi": "خطری بود که بدون بوق زدن از جاده عبور کند.", "examplePronunciation": "khatari bud ke bedoon-e boghzadan az jādah oboor konad.", "exampleEnglish": "It was dangerous to cross the road without honking."},
    {"word": "مسجد", "pronunciation": "masjed", "meaning": "mosque", "exampleFarsi": "مردم هر روز برای نماز به مسجد می‌روند.", "examplePronunciation": "mardom har roz baraye namaz be masjed merawand.", "exampleEnglish": "People go to the mosque every day for prayers."},
    {"word": "مقصد", "pronunciation": "maqsad", "meaning": "destination, goal, aim", "exampleFarsi": "مقصد سفرشان پاریس بود.", "examplePronunciation": "maqsad-e safarshān Pāris bud.", "exampleEnglish": "Their travel destination was Paris."},
    {"word": "مانع", "pronunciation": "maane", "meaning": "obstacle, hindrance, barrier", "exampleFarsi": "رعایت مسائل بهداشتی مانع گسترش بیماری شده است.", "examplePronunciation": "ra'ayat-e masa'el-e behdāshti māne gostareshe bimāri shodah ast.", "exampleEnglish": "Observing health issues has prevented the spread of the disease."},
    {"word": "سازش", "pronunciation": "sāzesh", "meaning": "compromise, collusion", "exampleFarsi": "سازش با دشمنان.", "examplePronunciation": "sāzesh bā doshmanān.", "exampleEnglish": "Colluding with enemies."},
    {"word": "شاید", "pronunciation": "shāyad", "meaning": "perhaps, maybe", "exampleFarsi": "شاید این راه حل بهتری باشد.", "examplePronunciation": "shāyad in rāh-hal behtari bāshad.", "exampleEnglish": "Maybe this is a better solution."},
    {"word": "ماتم", "pronunciation": "mātam", "meaning": "mourning, lamentation", "exampleFarsi": "ماتم برای یک فرد متوفی.", "examplePronunciation": "mātam barā-ye yak fard-e matufi.", "exampleEnglish": "Mourning for a deceased person."},
    {"word": "لطیف", "pronunciation": "latif", "meaning": "delicate, subtle, gentle", "exampleFarsi": "حرکت‌های لطیف آرامش بخش هستند.", "examplePronunciation": "harakāt-hāye latif ārāmesh bakhsh hastand.", "exampleEnglish": "Gentle movements are calming."},
    {"word": "سپاس", "pronunciation": "sepās", "meaning": "gratitude, thanks", "exampleFarsi": "از کمک شما سپاسگزاریم.", "examplePronunciation": "az komak-e shomā sepās-gozārim.", "exampleEnglish": "We are grateful for your help."},
    {"word": "لاغر", "pronunciation": "lāghar", "meaning": "thin, skinny", "exampleFarsi": "او به دلیل نداشتن تغذیه‌ی کافی لاغر شده بود.", "examplePronunciation": "oo bā dalil-e nadāshtan-e taghzia-ye kāfi lāghar shoda bud.", "exampleEnglish": "He had become skinny due to lack of adequate nutrition."},
    {"word": "خارش", "pronunciation": "khāresh", "meaning": "itch", "exampleFarsi": "خارش دارم.", "examplePronunciation": "khāresh dāram", "exampleEnglish": "I have an itch."},
    {"word": "مژده", "pronunciation": "mozhdah", "meaning": "good news", "exampleFarsi": "مژده خوشحال کننده بود.", "examplePronunciation": "mozhdah khoshhāl konanda bood", "exampleEnglish": "The news was heartening."},
    {"word": "دلبر", "pronunciation": "delbar", "meaning": "beloved, sweetheart", "exampleFarsi": "دلبر من.", "examplePronunciation": "delbar-e man", "exampleEnglish": "My beloved."},
    {"word": "عجیب", "pronunciation": "ajib", "meaning": "strange, odd", "exampleFarsi": "او یک رفتار عجیب داشت.", "examplePronunciation": "oo yak raftār-e ajib dāsht", "exampleEnglish": "He had a strange behavior."},
    {"word": "تکبر", "pronunciation": "takabbor", "meaning": "arrogance, pride", "exampleFarsi": "تکبر هیچوقت خوب نیست.", "examplePronunciation": "takabbor hichwaqt khub nest", "exampleEnglish": "Pride is never good."},
    {"word": "غروب", "pronunciation": "ghorub", "meaning": "sunset", "exampleFarsi": "غروب آفتاب را دوست دارم.", "examplePronunciation": "ghorub-e āftāb rā dost dāram", "exampleEnglish": "I like the sunset."},
    {"word": "توسط", "pronunciation": "tawassot", "meaning": "by, through, via", "exampleFarsi": "این پیام توسط ایمیل فرستاده شد.", "examplePronunciation": "in payam tawassot-e email ferestada shod", "exampleEnglish": "This message was sent via email."},
    {"word": "تابو", "pronunciation": "tābu", "meaning": "taboo", "exampleFarsi": "مصرف مواد مخدر توسط جامعه به عنوان یک مسأله‌ی تابو شناخته می‌شود.", "examplePronunciation": "masrafe mawad-e mokhader tawassot-e jame'a ba 'onwan-e yak mas'ale-ye tabu shenakhta meshavad", "exampleEnglish": "Drug use is considered a taboo issue in society."},
    {"word": "کوشش", "pronunciation": "kooshesh", "meaning": "effort, attempt", "exampleFarsi": "برای رسیدن به اهداف، باید کوشش بیشتری کرد.", "examplePronunciation": "baraye rasidan ba ahdaf, bayad kooshesh beshtar-i kard", "exampleEnglish": "To achieve goals, more effort should be made."},
    {"word": "خانه", "pronunciation": "khāna", "meaning": "house, home", "exampleFarsi": "خانه‌ی ما در شهر مرکزی واقع است.", "examplePronunciation": "khāna-ye mā dar shahr-e markazi vāqe' ast", "exampleEnglish": "Our home is located in the central city."},
    {"word": "کلمه", "pronunciation": "kalema", "meaning": "word", "exampleFarsi": "معنی کلمات را بلد باشید.", "examplePronunciation": "ma'naye kalemat ra balad bashid", "exampleEnglish": "Understand the meanings of the words."},
    {"word": "شعار", "pronunciation": "sho'ar", "meaning": "slogan", "exampleFarsi": "شعار این شرکت 'نوآوری و کیفیت' است.", "examplePronunciation": "sho'ar-e in sherkat 'nawawari-o kaifiat' ast", "exampleEnglish": "The slogan of this company is 'Innovation and Quality'."},
    {"word": "خلقت", "pronunciation": "khalqat", "meaning": "creation", "exampleFarsi": "خلقت آدمی در تورات و قرآن مورد بحث قرار گرفته است.", "examplePronunciation": "khalqat-e adami dar torat-o quran mawrede bahs qarar gerefta ast", "exampleEnglish": "The creation of man is discussed in the Torah and Quran."},
    {"word": "خلاف", "pronunciation": "khelaf", "meaning": "opposite, contrary", "exampleFarsi": "رفتار او با مقررات شرکت خلاف بود.", "examplePronunciation": "raftar-e oo ba moqarrarat-e sherkat khelaf bud", "exampleEnglish": "His behavior was contrary to the company's regulations."},
    {"word": "یلدا", "pronunciation": "yaldā", "meaning": "Yalda (winter solstice celebration)", "exampleFarsi": "یلدای سال گذشته با خانواده بودیم.", "examplePronunciation": "Yalda-ye saal-e gozashta ba khanawada bodim", "exampleEnglish": "We were with family for last year's Yalda celebration."},
    {"word": "لانه", "pronunciation": "lāna", "meaning": "nest", "exampleFarsi": "پرندگان همیشه لانه خود را برای تخم‌گذاری می‌سازند.", "examplePronunciation": "parandagān hamesha lāna-e khod rā barāye takhmagozāri mesāzanand.", "exampleEnglish": "Birds always build their nests for egg-laying."},
    {"word": "چربی", "pronunciation": "charbi", "meaning": "fat", "exampleFarsi": "شما باید غذاهای پرچربی را کمتر بخورید.", "examplePronunciation": "shomā bāyad ghazāhā-ye porchārbi rā kamtar bokhorid.", "exampleEnglish": "You should eat less fatty foods."},
    {"word": "عازم", "pronunciation": "āzaem", "meaning": "intending, heading towards", "exampleFarsi": "من به دانشگاه عازم می‌شوم.", "examplePronunciation": "man ba dāneshgāh āzaem meshawam.", "exampleEnglish": "I am heading towards the university."},
    {"word": "غارت", "pronunciation": "ghārat", "meaning": "plunder, raid", "exampleFarsi": "مجرمان به خانه‌ها غارت کردند.", "examplePronunciation": "mojremān ba khānahā ghārat kardand.", "exampleEnglish": "Criminals raided the houses."},
    {"word": "کیسه", "pronunciation": "kisa", "meaning": "bag, sack", "exampleFarsi": "من یک کیسه برای مسافرت با خودم می‌برم.", "examplePronunciation": "man yak kisa barāye mosāferat bā khodam mibaram.", "exampleEnglish": "I take a bag with me for traveling."},
    {"word": "حسرت", "pronunciation": "hasrat", "meaning": "regret, remorse", "exampleFarsi": "او برای از دست دادن فرصتی حسرت می‌خورد.", "examplePronunciation": "oo barāye az dast dādan-e fersati hasrat mikhord.", "exampleEnglish": "He regrets missing an opportunity."},
    {"word": "غلیظ", "pronunciation": "ghaliz", "meaning": "thick, dense", "exampleFarsi": "این شیرینی خیلی غلیظ و خوشمزه است.", "examplePronunciation": "in shirini kheli ghaliz wa khoshmaza ast.", "exampleEnglish": "This pastry is very thick and delicious."},
    {"word": "رویا", "pronunciation": "royā", "meaning": "dream", "exampleFarsi": "او هر شب رویاهای خوبی می‌بیند.", "examplePronunciation": "oo har shab royāhā-ye khubi mibinad.", "exampleEnglish": "He has good dreams every night."},
    {"word": "شگفت", "pronunciation": "shogheft", "meaning": "astonishment, wonder", "exampleFarsi": "او با شگفتی نگاه می‌کرد.", "examplePronunciation": "oo bā shoghefi negāh mekard.", "exampleEnglish": "He looked with astonishment."},
    {"word": "زغال", "pronunciation": "zoghāl", "meaning": "coal", "exampleFarsi": "زغال اینجا خیلی گران است.", "examplePronunciation": "zoghāl injā kheli gerān ast.", "exampleEnglish": "Coal is very expensive here."},
    {"word": "شمال", "pronunciation": "shamāl", "meaning": "north", "exampleFarsi": "شمال کشور اغلب هوای سردی دارد.", "examplePronunciation": "shamāl-e keshwar aghlab āb-o hawā-ye sard-i dārad.", "exampleEnglish": "The north of the country usually has a cold climate."},
    {"word": "لیمو", "pronunciation": "lemu", "meaning": "lemon", "exampleFarsi": "یک قاشق آب لیمو را به چای خود اضافه کنید.", "examplePronunciation": "yak qāsheq āb-e lemu rā ba chāy-e khod eżāfa konid.", "exampleEnglish": "Add a spoonful of lemon juice to your tea."},
    {"word": "ظاهر", "pronunciation": "zaher", "meaning": "appearance", "exampleFarsi": "ظاهر این کتاب خیلی جالب است.", "examplePronunciation": "zaher-e in ketāb kheli jāleb ast.", "exampleEnglish": "The appearance of this book is very interesting."},
    {"word": "کاشی", "pronunciation": "kāshi", "meaning": "tile", "exampleFarsi": "منزل من کاشی‌های سفید و سیاه دارد.", "examplePronunciation": "manzel-e man kāshi-hā-ye safid-o siyāh dārad.", "exampleEnglish": "My house has black and white tiles."},
    {"word": "عاشق", "pronunciation": "āsheq", "meaning": "lover", "exampleFarsi": "او یک عاشق هنر است.", "examplePronunciation": "oo yak āsheq-e honar ast.", "exampleEnglish": "He is an art lover."},
    {"word": "تفهم", "pronunciation": "tafahom", "meaning": "understanding", "exampleFarsi": "برای بهبود روابط فردی باید از تفهم و درک صحیح استفاده کرد.", "examplePronunciation": "barāy-e behbod-e rawābet-e fardi bāyad az tafahom-o dark-e sahih estefāda kard.", "exampleEnglish": "To improve personal relationships, one must use proper understanding and perception."},
    {"word": "شدید", "pronunciation": "shadid", "meaning": "intense, severe", "exampleFarsi": "اینجا هوا خیلی شدید سرد است.", "examplePronunciation": "injā hawā kheli shadid sard ast.", "exampleEnglish": "The weather here is very intensely cold."},
    {"word": "فکری", "pronunciation": "fekri", "meaning": "intellectual, mental", "exampleFarsi": "کتاب‌های فکری را بیشتر دوست دارم.", "examplePronunciation": "ketābhā-ye fekri rā besh-tar dost dāram.", "exampleEnglish": "I prefer intellectual books books."},
    {"word": "شبنم", "pronunciation": "shabnam", "meaning": "dew", "exampleFarsi": "صبح‌ها همیشه شبنم روی برگ‌های گل‌هاست.", "examplePronunciation": "sobh-hā hamisha shabnam ru-ye barghā-ye gol-hāst.", "exampleEnglish": "In the mornings, dew is always on the petals of the flowers."},
    {"word": "سایر", "pronunciation": "sāyer", "meaning": "other, remaining", "exampleFarsi": "کتاب‌هایی از نویسندگان سایر کشورها را هم می‌خوانم.", "examplePronunciation": "ketābhā-ye az nawisandegān-e sāyer-e keshwar-hā rā ham mikhanam.", "exampleEnglish": "I also read books by authors from other countries."},
    {"word": "تحول", "pronunciation": "tahavol", "meaning": "change, transformation", "exampleFarsi": "در دهه‌های اخیر، تحولات فراوانی در حوزه‌ی فناوری رخ داده است.", "examplePronunciation": "dar dah-ha-ye akhir, tahawolāt-e farāwāni dar hawze-ye fanāwari rokh dādeh ast.", "exampleEnglish": "In recent decades, there have been significant changes in the field of technology."},
    {"word": "حرمت", "pronunciation": "hormat", "meaning": "respect, dignity", "exampleFarsi": "می‌دانی که باید به حرمت دیگران احترام بگذاری.", "examplePronunciation": "medāni ke bāyad ba hormat-e digarān ehterām begozāri.", "exampleEnglish": "You know that you should respect the dignity of others."},
    {"word": "غالب", "pronunciation": "ghāleb", "meaning": "dominant, prevailing", "exampleFarsi": "در مسابقه فوتبال تیم ما غالب بود.", "examplePronunciation": "dar mosābaqe-ye futbāl team-e mā ghāleb bud.", "exampleEnglish": "Our team was dominant in the football match."},
    {"word": "غوغا", "pronunciation": "ghoghā", "meaning": "hullabaloo, commotion", "exampleFarsi": "همه‌ی این غوغا را نمی‌فهمم.", "examplePronunciation": "hame-ye in ghoghā rā namefahmam.", "exampleEnglish": "I don't understand all this commotion."},
    {"word": "لحاظ", "pronunciation": "lehāz", "meaning": "consideration, regard", "exampleFarsi": "باید به همه‌ی لحاظات امور توجه داشت.", "examplePronunciation": "bāyad bش hama-ye lehāzāt-e omoor tawajjoh dāsht.", "exampleEnglish": "We should pay attention to all aspects of the matter."},
    {"word": "غریب", "pronunciation": "gharib", "meaning": "strange, foreign", "exampleFarsi": "به چهره‌ی غریب این شهر تعجب کردم.", "examplePronunciation": "be chehre-ye gharib-e in shahr ta'jjob kardam.", "exampleEnglish": "I was surprised by the strange face of this city."},
    {"word": "روغن", "pronunciation": "roghan", "meaning": "oil", "exampleFarsi": "برای پخت غذا از روغن زیتون استفاده می‌کنم.", "examplePronunciation": "barāye pokht-e ghazā az roghan-e zaytun estefāda mekonam.", "exampleEnglish": "I use olive oil for cooking."},
    {"word": "بیشک", "pronunciation": "bishak", "meaning": "without a doubt, certainly", "exampleFarsi": "بی‌شک آن کار را انجام می‌دهد.", "examplePronunciation": "beshak ān kār rā anjām midehad.", "exampleEnglish": "He certainly does that work."},
    {"word": "مفید", "pronunciation": "mofid", "meaning": "useful, beneficial", "exampleFarsi": "این کتاب برای یادگیری زبان مفید است.", "examplePronunciation": "in ketāb barāye yādgiri-ye zabān mofid ast.", "exampleEnglish": "This book is useful for learning a language."},
    {"word": "نحوه", "pronunciation": "nahwa", "meaning": "method, manner, way", "exampleFarsi": "نحوه پرداخت اینترنتی در فروشگاه آنلاین چگونه است؟", "examplePronunciation": "nahwa-ye pardākhte interneti dar forushgāh-e ānlāin cheghuna ast?", "exampleEnglish": "What is the method of online payment in the online store?"},
    {"word": "شادی", "pronunciation": "shādi", "meaning": "happiness, joy", "exampleFarsi": "شادی و خوشحالی اهداف بسیاری از ما در زندگی است.", "examplePronunciation": "shādi wa khoshhāli ahdafe bisyāri az mā dar zendagi ast.", "exampleEnglish": "Happiness and joy are goals for many of us in life."},
    {"word": "خفیف", "pronunciation": "khafif", "meaning": "light, easy", "exampleFarsi": "این کیف خیلی خفیف است.", "examplePronunciation": "in kif kheli khafif ast.", "exampleEnglish": "This bag is very light."},
    {"word": "جرات", "pronunciation": "jorāt", "meaning": "courage, bravery", "exampleFarsi": "او با جرات و شجاعت از خطرات می‌گذرد.", "examplePronunciation": "oo bā jorāt wa shojā-at az khatārāt megozarad.", "exampleEnglish": "He faces dangers with courage and bravery."},
    {"word": "گنده", "pronunciation": "gondé", "meaning": "fat, chubby", "exampleFarsi": "بچه‌ای که گنده است، همیشه به خنده است.", "examplePronunciation": "bachei ke gondé ast, hameshe be khanda ast.", "exampleEnglish": "A child who is chubby is always laughing."},
    {"word": "نقدی", "pronunciation": "naghdi", "meaning": "cash, in cash", "exampleFarsi": "تخفیف برای پرداخت نقدی در نظر گرفته شده است.", "examplePronunciation": "takhfif baraye pardakht-e naqdi dar nazar gerefta shode ast.", "exampleEnglish": "Discounts are offered for cash payments."},
    {"word": "ملکی", "pronunciation": "melki", "meaning": "belonging to property or real estate", "exampleFarsi": "این زمین ملکی من است.", "examplePronunciation": "in zamin-e melki-e man ast.", "exampleEnglish": "This land belongs to me."},
    {"word": "راضی", "pronunciation": "rāzi", "meaning": "satisfied, content", "exampleFarsi": "ما با خدمات این هتل بسیار راضی بودیم.", "examplePronunciation": "ma ba khadamat-e in hotel besyar rāzi budim.", "exampleEnglish": "We were very satisfied with the services of this hotel."},
    {"word": "کنیز", "pronunciation": "kaniz", "meaning": "maid, servant girl", "exampleFarsi": "در قرون گذشته، بسیاری از خانواده‌های ثروتمند، کنیز به خدمت داشتند.", "examplePronunciation": "dar qorun-e gozashta, basyar-i az khanawada-haye sarwatmand, kaniz ba khedmat dashtand.", "exampleEnglish": "In the past centuries, many wealthy families had maids."},
    {"word": "غفلت", "pronunciation": "ghaflat", "meaning": "negligence, heedlessness", "exampleFarsi": "به دلیل غفلت از دوستانمان دیر به جمع‌شان پیوستیم.", "examplePronunciation": "ba dalil-e ghaflat az dostan-e-man dir ba jame'-e-shan paywastim.", "exampleEnglish": "Due to negligence, we joined our friends late."},
    {"word": "ملیت", "pronunciation": "meliat", "meaning": "nationality, ethnicity", "exampleFarsi": "ملیت افراد به محل زادگاه آنها بستگی دارد.", "examplePronunciation": "meliat-e afrad ba mahal-e zadgah-e anha bastegi darad.", "exampleEnglish": "The nationality of individuals depends on their place of birth."},
    {"word": "نگاه", "pronunciation": "negah", "meaning": "look, glance, perspective", "exampleFarsi": "بهتر است به مسائل با نگاه مثبت نگریست.", "examplePronunciation": "behtar ast be masael ba negah-e mosbat negarist.", "exampleEnglish": "It is better to look at issues with a positive perspective."},
    {"word": "تهیه", "pronunciation": "tahiye", "meaning": "preparation, procurement", "exampleFarsi": "تهیه غذا برای دیگران یکی از نیکی‌های انسانی است.", "examplePronunciation": "tahiya-ye ghaza baraye digaran yaki az nikihaye ensani ast.", "exampleEnglish": "Preparing food for others is one of the human kindnesses."},
    {"word": "مطلع", "pronunciation": "motalea", "meaning": "informed, knowledgeable", "exampleFarsi": "او در مورد مسائل سیاسی بسیار مطلع است.", "examplePronunciation": "oo dar mawred-e masa'el-e siyāsi besyār motalea ast.", "exampleEnglish": "He is very knowledgeable about political issues."},
    {"word": "پوزش", "pronunciation": "pozesh", "meaning": "apology", "exampleFarsi": "من برای تأخیر پوزش می‌خواهم.", "examplePronunciation": "man barāye ta'khir pozesh mekhwāham.", "exampleEnglish": "I apologise for being late."},
    {"word": "دخول", "pronunciation": "dokhool", "meaning": "entry, entrance", "exampleFarsi": "دخول به مسابقه برای او خیلی مهم بود.", "examplePronunciation": "dokhool be mosābaqa barāye oo kheli mohem bud.", "exampleEnglish": "Entering the competition was very important for him."},
    {"word": "مردم", "pronunciation": "mardom", "meaning": "people", "exampleFarsi": "مردم باید حقوق اساسی خود را داشته باشند.", "examplePronunciation": "mardom bāyad haqoq-e āsāsi khod rā dāshtah bāshand.", "exampleEnglish": "People should have their basic rights."},
    {"word": "دکمه", "pronunciation": "dokma", "meaning": "button", "exampleFarsi": "لطفاً دکمه قرمز را بفشارید.", "examplePronunciation": "lotfan dokma-ye qermez rā befeshārid.", "exampleEnglish": "Please press the red button."},
    {"word": "وفات", "pronunciation": "vafāt", "meaning": "death, passing away", "exampleFarsi": "وفات مادرم برایم خیلی دردناک بود.", "examplePronunciation": "wafāt-e mādaram barāyem kheli dardnāk bud.", "exampleEnglish": "My mother's passing away was very painful for me."},
    {"word": "نرده", "pronunciation": "narda", "meaning": "railing, fence", "exampleFarsi": "نرده‌های ایمنی می‌توانند از سقوط جلوگیری کنند.", "examplePronunciation": "nardahā-ye emani metawānand az soqoot jelawgiri konand.", "exampleEnglish": "Safety railings can prevent falling."},
    {"word": "توجه", "pronunciation": "tawajjoh", "meaning": "attention, consideration", "exampleFarsi": "لطفاً به توضیحات من توجه کنید.", "examplePronunciation": "lotfan ba tawzihāt-e man tawajjoh konid.", "exampleEnglish": "Please pay attention to my explanations."}
]
